html {
  font-size: 12.8px;
  font-family: 'Montserrat';
  letter-spacing: 0;
  scroll-behavior: smooth;
}

body {
  padding: 0;
}

:root {
  --color-dark-blue-1: #012d6c;
  --color-dark-blue-2: #335789;
  --color-dark-blue-3: #6681a6;
  --color-dark-blue-4: #99abc4;
  --color-dark-blue-5: #ccd5e1;
  --color-light-blue-1: #0071bd;
  --color-light-blue-2: #338dca;
  --color-light-blue-3: #66a9d7;
  --color-light-blue-4: #99c6e4;
  --color-light-blue-5: #cce2f1;
  --color-yellow-1: #f9ae3b;
  --color-yellow-2: #fabe62;
  --color-yellow-3: #fbce89;
  --color-yellow-4: #fcdeb0;
  --color-yellow-5: #fdeed7;
  --color-orange-1: #ff6a40;
  --color-orange-2: #ff8766;
  --color-orange-3: #ffa58c;
  --color-orange-4: #ffc3b2;
  --color-orange-5: #ffe1d8;
  --color-grey-1: #3f3f3f;
  --color-grey-2: #9b9b9b;
  --color-grey-3: #ececec;
  --color-grey-4: #f8f8f8;
  --color-white: #ffffff;
}

select.selected {
  color: var(--color-grey-1);
}

.header-logo img,
.footer-logo img {
  height: 30px;
}

/* HEADER */

.main-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 5rem;
  font-weight: 600;
  margin: 0 auto -5rem;
  padding: 0 4rem;
  z-index: 1;
}

.header-nav {
  display: none;
}
/*
.header-nav.active {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #1a1666;
  color: white;
  z-index: 2;
} */

.header-nav.active .nav-links,
.header-nav.active .nav-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 50px auto 20px;
}

@media (max-width: 767px) {
  .header-nav.active .nav-links,
  .header-nav.active .nav-buttons {
    align-items: flex-start;
  }
  .header-nav.active .nav-buttons {
    justify-content: center;
  }
}

.header-nav.active a {
  margin-bottom: 10px;
  font-weight: 400;
  font-size: 21px;
  line-height: 35px;
}
.nav-show {
  position: absolute;
  right: 12px;
  display: block;
  height: 50px;
  width: 50px;
  cursor: pointer;
}
.nav-close {
  display: block;
  height: 50px;
  width: 50px;
  top: 13px;
  position: absolute;
  cursor: pointer;
  right: 12px;
}
.nav-show .nav-close {
  cursor: pointer;
  position: relative;
  width: 50px;
  height: 50px;
}

.nav-close {
  position: absolute;
  right: 5px;
}

span.icon-bar {
  display: block;
  position: absolute;
  right: 12px;
  width: 20px;
  height: 3px;
  border-radius: 2px;
  background-color: var(--color-white);
}
.icon-bar:nth-child(1) {
  top: 17px;
}
.icon-bar:nth-child(2) {
  top: 24px;
}
.icon-bar:nth-child(3) {
  top: 31px;
}

.nav-close .icon-bar {
  background-color: white;
}
.nav-close .icon-bar:nth-child(1) {
  top: 24px;
  transform: rotate(45deg);
}
.nav-close .icon-bar:nth-child(2) {
  top: 24px;
  transform: rotate(-45deg);
}

.register-button img {
  display: none;
}

.hero-section {
  background: url(../images/hero-background.svg);
  background-size: cover;
  background-position: left bottom;
  background-repeat: no-repeat;
  font-weight: 600;
  /* padding: 0 70px; */
  /* text-align: center; */
}

.hero-section .container {
  display: flex;
  justify-content: space-between;
  max-width: calc(1920px - 2 * 120px);
  margin: 0 auto;
  padding: 10rem 20px 100px;
}

/* .hero-text {
  flex: 1;
  font-weight: 600;
  margin: 0 auto;
  max-width: 750px;
} */

.hero-text h1 {
  color: var(--color-white);
  font-weight: 700;
  font-size: 3rem;
  line-height: 1.3;
}

.hero-text p {
  margin-top: 15px;
  font-weight: 400;
  color: var(--color-white);
  font-size: 1.125rem;
  font-weight: 300;
}

.hero-text a {
  display: inline-block;
  background-color: var(--color-yellow-1);
  color: var(--color-white);
  font-size: 1.125rem;
  font-weight: 700;
  padding: 1.25rem 3.75rem;
  margin-top: 3rem;
  border-radius: 15px;
}

/* MONETIZE SECTION */
.flex-box {
  display: flex;
}

.monetize-section-cards {
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 536px;
  justify-content: space-between;
  align-items: stretch;
}
.monetize-section-cards div {
  line-height: 1.9375rem;
  width: calc(50% - 10px);
  /* background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 15px 30px #0000000d; */
  border-radius: 15px;
  height: 122px;
  display: flex;
  /* align-items: center;
  justify-content: center; */
  margin-top: 30px;
}
.monetize-section-cards div img {
  width: auto;
  margin: 0 auto;
}
.monetize-section-cards div p {
  line-height: 3.2;
  margin: 0;
  top: 1953px;
  letter-spacing: 0;
  color: #99c6e4;
}
.monetize-section h2 {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  max-width: 480px;
  font-size: 2.375rem;
  color: rgba(1, 45, 108, 1);
}
.monetize-section p {
  max-width: 600px;
  font-family: Montserrat;
  line-height: 1.9375rem;
  font-style: normal;
  font-weight: lighter;
  font-size: 1.3125rem;
  color: rgba(155, 155, 155, 1);
  margin-top: 20px;
}
.monetize-section p b {
  font-weight: 600;
}
.right-aligned {
  text-align: right;
}
.right-aligned h2,
.right-aligned p {
  margin: 0 0 0 auto;
}
.yellow-text {
  color: rgba(249, 174, 59, 1);
}
.red-text {
  color: #ff6a40;
}
.blue-text {
  color: #0071bd;
}
.section-links {
  width: 182px;
  height: 60px;
  margin-top: 20px;
  border: 3px solid #f9ae3b;
  border-radius: 30px;
  display: flex;
  background: #f9ae3b;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  color: #fff;
  font-size: 1rem;
  transition: all 0.4s;
}
.section-links:hover {
  background: rgba(249, 173, 59, 0.8);
}
.right-aligned a {
  float: right;
}
/* MONETIZE SECTION */

/* GET PAID SECTION */
.get-paid-section {
  text-align: center;
}
.get-paid-section h2 {
  font-size: 2.375rem;
  font-weight: 600;
  color: #012d6c;
  margin-bottom: 30px;
}
.get-paid-section h3 {
  color: #9b9b9b;
  font-size: 1.5rem;
}
.get-paid-section p {
  max-width: 940px;
  line-height: 1.9375rem;
  margin: 0 auto;
  font-size: 1.3125rem;
  color: #9b9b9b;
  font-weight: 300;
}
.payment-logos {
  position: relative;
  margin: 20px auto;
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
}
.payment-logos::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  bottom: 0;
  background: -webkit-linear-gradient(
    to right,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0) 49%,
    rgba(255, 255, 255, 1) 100%
  );
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0) 49%,
    rgba(255, 255, 255, 1) 100%
  );
}

@keyframes backgroundScroll {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: -1800px 0;
  }
  100% {
    background-position: -3600px 0;
  }
}
.payment-logos img {
  width: 160px;
  height: 100px;
  margin: 0 20px;
  object-fit: contain;
}
/* GET PAID SECTION */

.experienced-team-section {
  margin: 60px auto;
}
.experienced-team-section h3 {
  font-size: 1.75rem;
  font-weight: 600;
  font: Semi Bold 28px/35px Montserrat;
  letter-spacing: 0;
  color: #012d6c;
  opacity: 1;
}
/* TESTIMONIALS SECTION */

.testimonials-section .container {
  display: flex;
  height: 100%;
  max-width: 1530px;
  margin: 0 auto;
}

.testimonials-section .heading {
  font-weight: 600;
  font-size: 1.75rem;
  line-height: 2.1875rem;
  color: #012d6c;
  flex: 1;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.testimonials-section .heading p {
  width: 70%;
}

.testimonials-section .slider {
  width: 50%;
  display: flex;
  align-items: center;
}

.testimonials-section .owl-carousel {
  max-width: 670px;
}

.testimonials-section .slider .nav-button {
  margin: 10px;
}

.testimonials-section .slider .arrow-left {
  transform: rotateY(180deg);
}

.testimonials-section .slider .owl-item .card {
  opacity: 0.5;
  -webkit-transform: scale3d(0.8, 0.8, 1);
  transform: scale3d(0.8, 0.8, 1);
  transition: 0.3s transform ease-in-out;
}

.testimonials-section .slider .owl-item.active.center .card {
  opacity: 1;
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
}

.testimonials-section .card-wrapper {
  width: 550px;
}

.testimonials-section .card {
  background: white;
  border-radius: 25px;
  padding: 40px;
  max-width: 550px;
  display: inline-block;
  white-space: normal;
}

.testimonials-section .card .header {
  display: flex;
}

.testimonials-section .card .header .person {
  margin-bottom: 20px;
}

.testimonials-section .card .header .image {
  width: 52px;
  height: 52px;
  margin-bottom: 20px;
}

.testimonials-section .card .header .name {
  font-size: 1.3125rem;
  line-height: 1.625rem;
  font-weight: 600;
  color: #3f3f3f;
  display: block;
}

.testimonials-section .card .header .title {
  font-size: 1rem;
  line-height: 1.1875rem;
  font-weight: 300;
  color: #3f3f3f;
  display: block;
}

.testimonials-section .card .header .content {
  font-size: 1rem;
  line-height: 1.9375rem;
  font-weight: 300;
  color: #9b9b9b;
}

/* EVENTS SECTION */
.events-section {
  margin-top: 7%;
}

.events-section .container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 10rem;
  margin: 0 auto;
}

.events-section h2 {
  font-size: 2rem;
  font-weight: 700;
  color: var(--color-light-blue-1);
}

.events-section p {
  margin-top: 1.5rem;
  font-size: 1.125rem;
  font-weight: 400;
  color: var(--color-grey-1);
}

.events-section .cards {
  display: flex;
  margin: 70px 0;
  flex-wrap: wrap;
  align-content: center;
}

.events-section .card {
  flex: 1;
  background: #ececec;
  border-radius: 30px;
  margin-right: 16px;
  display: flex;
  flex-direction: column;
  max-width: 410px;
  margin-bottom: 16px;
}

.events-section .card:last-child {
  margin-right: 0;
}

.events-section .card .header {
  font-size: 1.75rem;
  line-height: 2.1875rem;
  font-weight: 600;
  color: white;
  padding: 55px;
  flex: 7;
  position: relative;
  border-radius: 30px 30px 0 0;
}

.events-section .card .header:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 30px 30px 0 0;
  background: #0071bd;
  opacity: 0.77;
}

.events-section .card .header span {
  position: relative;
}

.events-section .card .content {
  font-size: 1.3125rem;
  line-height: 1.5625rem;
  font-weight: 400;
  color: #012d6c;
  padding: 30px 55px;
  flex: 3;
}

.events-section .card .content span {
  display: block;
}

.events-section .call-action {
  display: inline-block;
  color: white;
  font-size: 1rem;
  line-height: 1.1875rem;
  font-weight: 600;
  padding: 25px 70px;
  border-radius: 15px;
  background-color: #f9ae3b;
  margin-top: 20px;
  margin-bottom: 50px;
}

.publisher-app-section .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(265.74deg, #1179bf -0.24%, #012d6c 102.38%);
  border-radius: 32px;
}

.publisher-app-section .container .right-side {
  padding-right: 5rem;
  margin-top: -7rem;
}

.publisher-app-section .container .right-side img {
  width: 100%;
  height: 100%;
}

.publisher-app-section p {
  font-weight: 400;
  font-size: 1.125rem;
  color: var(--color-white);
}

.publisher-app-section p:not(:first-of-type) {
  margin-top: 1rem;
  width: 70%;
}

@media (max-width: 959px) {
  .publisher-app-section p:not(:first-of-type) {
    width: 100%;
  }
}

.publisher-app-section .left-side p:nth-child(2) {
  margin-top: 1.5rem;
  font-weight: 700;
  font-size: 2rem;
  color: var(--color-white);
}

.publisher-app-downloads {
  margin-top: 2rem;
  display: flex;
  justify-content: flex-start;
}

.publisher-app-download img {
  height: 3rem;
  width: 10rem;
}

.publisher-app-download:not(:first-of-type) {
  margin-left: 1rem;
}

/* BLOG POSTS SECTION */
.blog-posts-section .container {
  max-width: calc(1920px - 2 * (140px - 5px));
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
}

.blog-posts-section .section-title {
  font-size: 2.375rem;
  line-height: 2.9375rem;
  font-weight: 600;
  color: #012d6c;
  margin: 60px 0 0;
  display: block;
}

.blog-posts-section .cards {
  display: flex;
  margin: 60px 0;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
}

.blog-posts-section .card {
  background: #ececec;
  border-radius: 30px;
  margin-right: 30px;
  display: flex;
  flex-direction: column;
  max-width: 350px;
  margin-bottom: 30px;
  box-shadow: 0px 15px 30px #338dca26;
}

.blog-posts-section .card .image {
  position: relative;
  border-radius: 30px 30px 0 0;
  height: 190px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.blog-posts-section .card .image:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 30px 30px 0 0;
  background: #0071bd;
  opacity: 0.46;
}

.blog-posts-section .card .content {
  font-size: 1rem;
  line-height: 1.1875rem;
  text-align: center;
  font-weight: 300;
  color: #9b9b9b;
  padding: 30px 26px 0;
  flex: 3;
  background-color: white;
  border-radius: 0 0 30px 30px;
  display: flex;
  flex-direction: column;
}

.blog-posts-section .card .content span {
  display: block;
  font-size: 1.3125rem;
  line-height: 1.625rem;
  font-weight: 600;
  text-align: left;
  color: #012d6c;
  margin-bottom: 16px;
}
.blog-posts-section .card .content p {
  text-align: left;
  margin-bottom: 30px;
}
.blog-posts-section .card .content .call-action {
  display: inline-block;
  color: white;
  font-size: 1rem;
  line-height: 1.1875rem;
  font-weight: 600;
  padding: 11px 45px;
  border-radius: 15px;
  background-color: #f9ae3b;
  margin: auto auto 30px;
  transition: all 0.4s;
}

.blog-posts-section {
  margin-top: 10rem;
}

.blog-posts-section .container {
  padding: 0 10rem;
}

.blog-posts-section h2 {
  color: var(--color-light-blue-1);
  font-size: 2rem;
  font-weight: 700;
}

.blog-posts-section p {
  margin-top: 1.5rem;
  color: var(--color-grey-1);
  font-size: 1.125rem;
  font-weight: 400;
}

.blog-post-image {
  width: 100%;
  height: 15rem;
  background-size: cover;
  filter: drop-shadow(0px 20px 40px rgba(0, 0, 0, 0.16));
  border-radius: 16px;
}

.blog-post-image a {
  display: block;
  width: 100%;
  height: 100%;
}

.blog-post-data > a > h3 {
  color: var(--color-grey-1);
  font-size: 1.5rem;
  font-weight: 700;
}

.blog-post-data p {
  font-weight: 400;
  margin-top: 1rem !important;
  color: var(--color-grey-2);
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.blog-post-data span {
  display: block;
  font-weight: 600;
  margin-top: 1rem;
}

.blog-post-data a {
  display: inline-block;
  font-weight: 600;
  margin-top: 1rem;
  color: var(--color-yellow-1);
}

.blog-read-more-arrow {
  background-image: url(../images/blog/read-more-arrow.svg);
  background-size: 100% 100%;
  width: 1rem;
  display: inline-block !important;
  height: 1rem;
  margin-top: -2px !important;
  vertical-align: middle;
  background-repeat: no-repeat;
}

/* JOIN SECTION */

.join-section {
  background-color: #012d6c;
  color: white;
  overflow: hidden;
  height: 480px;
  position: relative;
}

.join-section-centered {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.join-section-centered h2 {
  max-width: 720px;
  font-size: 1.875rem;
  line-height: 2.1875rem;
  font-weight: 700;
  letter-spacing: 0;
  color: #ffffff;
}
.join-section-centered h3 {
  font-weight: 600;
  font-size: 1.6rem;
  max-width: 400px;
  margin: 0 auto;
}
/* .join-section-centered a{
  width: 260px;
  height: 70px;
  background: #F9AE3B;
  border-radius: 15px;
  font-weight: 400;
} */
/* FOOTER */

start-section h2 {
  color: var(--color-white);
  font-weight: 700;
  font-size: 3.75rem;
}

start-section a {
  display: inline-block;
  padding: 1.5rem 2.75rem;
  margin-top: 5rem;
  background-color: var(--color-yellow-1);
  color: white;
  border-radius: 15px;
  font-weight: 700;
  font-size: 1.125rem;
}

footer {
  background-color: var(--color-white);
}

footer .container {
  /* margin-top: 5rem; */
  max-width: calc(1920px - 2 * (140px - 5px));
  display: flex;
  flex-direction: column;
  align-items: center;
}

footer .content {
  position: relative;
  width: 100%;
  max-width: calc(1920px - 2 * (280px - 5px));
  margin: 35px auto 0px;
  text-align: center;
  border-bottom: 1px solid var(--color-grey-3);
}

footer .copyright {
  padding: 2.25rem 0;
  text-align: center;
  font-size: 0.875rem;
  font-weight: 400;
  color: var(--color-dark-blue-1);
}

footer .contact-us {
  position: relative;
}
footer .contact-us span {
  display: block;
}

footer .contact-us .title {
  font-size: 1.3125rem;
  line-height: 1.625rem;
  font-weight: 600;
  color: #fff;
  margin-bottom: 20px;
}
footer .social {
  margin-top: 1.75rem;
}

footer .social > a {
  display: inline-block;
}

footer .social img {
  width: 1.75rem;
  height: 1.75rem;
  object-fit: contain;
  margin-right: 1.5rem;
}

footer .companies {
  margin-top: 2rem;
  font-size: 0.85rem;
  color: var(--color-dark-blue-1);
  font-weight: 400;
}

footer .companies > p:not(:first-of-type) {
  margin-top: 1rem;
  display: flex;
  align-items: center;
}
footer .companies .location-icon {
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: inline-block;
  margin-right: 1rem;
  width: 2.125rem;
  height: 2.125rem;
}

footer .companies .location-icon.icon-1 {
  background-image: url(../images/general/footer/locations/new-york.png);
}

footer .companies .location-icon.icon-2 {
  background-image: url(../images/general/footer/locations/dubai.png);
}

footer .companies .location-icon.icon-3 {
  background-image: url(../images/general/footer/locations/podgorica.png);
}

footer .contact-us .email {
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 600;
  max-width: 60%;
  color: var(--color-dark-blue-1);
  margin: 30px auto;
}
.email {
  display: block;
}

footer .links .group-title {
  display: block;
  font-size: 1.5rem;
  line-height: 1.625rem;
  font-weight: 600;
  color: var(--color-dark-blue-1);
  margin-bottom: 1.5rem;
}

footer .links a {
  display: inline-block;
  font-size: 1.25rem;
  font-weight: 300;
  color: var(--color-dark-blue-1);
  margin-bottom: 1.5rem;
}

footer .links a:hover {
  opacity: 0.7;
}

.payment-methods {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1.25rem;
}
/* ANIMATED STARS BACKGROUND  */

#stars {
  width: 1px;
  height: 1px;
  background: transparent;
  box-shadow: 581px 1751px #fff, 1686px 604px #fff, 1463px 830px #fff,
    1666px 1570px #fff, 1427px 130px #fff, 1962px 300px #fff, 1428px 659px #fff,
    730px 1722px #fff, 1332px 1010px #fff, 1225px 698px #fff, 1088px 139px #fff,
    1114px 1022px #fff, 827px 1034px #fff, 1536px 1129px #fff, 311px 1833px #fff,
    947px 450px #fff, 1324px 306px #fff, 1333px 608px #fff, 805px 1669px #fff,
    1861px 86px #fff, 168px 457px #fff, 1683px 1054px #fff, 1280px 173px #fff,
    764px 1512px #fff, 1717px 261px #fff, 1394px 139px #fff, 1736px 609px #fff,
    1052px 165px #fff, 1120px 817px #fff, 172px 1659px #fff, 931px 1068px #fff,
    1851px 1556px #fff, 1884px 862px #fff, 1343px 1376px #fff, 138px 1169px #fff,
    1592px 584px #fff, 975px 141px #fff, 1216px 933px #fff, 879px 1933px #fff,
    320px 2000px #fff, 3px 1212px #fff, 1643px 1884px #fff, 700px 950px #fff,
    417px 542px #fff, 1663px 1246px #fff, 967px 1831px #fff, 1162px 928px #fff,
    983px 770px #fff, 1000px 1544px #fff, 23px 1081px #fff, 1925px 787px #fff,
    309px 612px #fff, 1350px 1084px #fff, 1736px 1107px #fff, 605px 485px #fff,
    1067px 481px #fff, 1176px 1506px #fff, 95px 685px #fff, 1073px 30px #fff,
    1085px 1610px #fff, 304px 1339px #fff, 1185px 1641px #fff,
    1368px 1460px #fff, 1006px 663px #fff, 1393px 739px #fff, 1705px 1361px #fff,
    1330px 28px #fff, 517px 665px #fff, 527px 1516px #fff, 947px 1777px #fff,
    1627px 1720px #fff, 956px 346px #fff, 1065px 1988px #fff, 1385px 725px #fff,
    1832px 602px #fff, 893px 421px #fff, 605px 1928px #fff, 1065px 1823px #fff,
    1794px 1641px #fff, 1857px 1369px #fff, 981px 1279px #fff, 793px 1843px #fff,
    688px 424px #fff, 1492px 502px #fff, 880px 92px #fff, 726px 1322px #fff,
    1751px 476px #fff, 531px 1133px #fff, 479px 666px #fff, 1581px 1340px #fff,
    1056px 93px #fff, 513px 416px #fff, 1162px 985px #fff, 995px 791px #fff,
    1890px 1409px #fff, 59px 83px #fff, 851px 1777px #fff, 1251px 47px #fff,
    1450px 1335px #fff, 400px 1159px #fff, 1144px 1044px #fff,
    1238px 1940px #fff, 1597px 842px #fff, 880px 738px #fff, 973px 1570px #fff,
    148px 1650px #fff, 502px 1937px #fff, 1655px 1603px #fff, 305px 1528px #fff,
    1914px 343px #fff, 440px 44px #fff, 85px 200px #fff, 951px 1520px #fff,
    221px 1577px #fff, 14px 1940px #fff, 368px 728px #fff, 280px 1189px #fff,
    70px 701px #fff, 1951px 1351px #fff, 644px 1747px #fff, 1308px 1341px #fff,
    1958px 425px #fff, 1566px 777px #fff, 1403px 950px #fff, 1693px 1957px #fff,
    720px 1446px #fff, 1734px 198px #fff, 20px 1283px #fff, 1442px 735px #fff,
    963px 1194px #fff, 1554px 1641px #fff, 424px 417px #fff, 724px 965px #fff,
    1104px 269px #fff, 1927px 693px #fff, 124px 1611px #fff, 1524px 1869px #fff,
    13px 1669px #fff, 85px 1820px #fff, 1519px 1585px #fff, 204px 956px #fff,
    1715px 597px #fff, 224px 1375px #fff, 656px 118px #fff, 1307px 203px #fff,
    591px 1008px #fff, 185px 1871px #fff, 1293px 264px #fff, 269px 83px #fff,
    1929px 910px #fff, 900px 1117px #fff, 752px 965px #fff, 678px 961px #fff,
    885px 1945px #fff, 1410px 325px #fff, 1131px 1453px #fff, 1135px 1126px #fff,
    1408px 1424px #fff, 1872px 320px #fff, 1021px 536px #fff, 832px 1492px #fff,
    1329px 1447px #fff, 522px 1017px #fff, 839px 1085px #fff, 314px 1531px #fff,
    1077px 909px #fff, 1215px 778px #fff, 1659px 1350px #fff, 844px 79px #fff,
    1248px 705px #fff, 1917px 1059px #fff, 82px 1779px #fff, 358px 1625px #fff,
    317px 1440px #fff, 52px 277px #fff, 1508px 532px #fff, 975px 389px #fff,
    764px 158px #fff, 1711px 1235px #fff, 1743px 28px #fff, 175px 1373px #fff,
    36px 372px #fff, 1138px 262px #fff, 1374px 1791px #fff, 685px 1448px #fff,
    1799px 798px #fff, 320px 52px #fff, 465px 1088px #fff, 157px 372px #fff,
    1082px 171px #fff, 188px 1109px #fff, 1743px 996px #fff, 1347px 883px #fff,
    1713px 171px #fff, 1904px 676px #fff, 781px 7px #fff, 1213px 490px #fff,
    1395px 1956px #fff, 655px 124px #fff, 1260px 1894px #fff, 1001px 1605px #fff,
    646px 1077px #fff, 78px 410px #fff, 457px 1974px #fff, 606px 1264px #fff,
    1150px 1928px #fff, 1906px 412px #fff, 1880px 105px #fff, 522px 1467px #fff,
    19px 1540px #fff, 755px 1721px #fff, 679px 81px #fff, 774px 1718px #fff,
    217px 258px #fff, 417px 471px #fff, 1944px 797px #fff, 1609px 619px #fff,
    1372px 948px #fff, 1452px 31px #fff, 868px 261px #fff, 197px 1620px #fff,
    1436px 1844px #fff, 389px 881px #fff, 1718px 1143px #fff, 689px 584px #fff,
    1328px 1099px #fff, 1560px 158px #fff, 731px 1671px #fff, 344px 159px #fff,
    1031px 1573px #fff, 1681px 328px #fff, 1226px 1673px #fff, 293px 643px #fff,
    642px 195px #fff, 984px 1001px #fff, 152px 211px #fff, 841px 1455px #fff,
    946px 1692px #fff, 101px 1655px #fff, 1310px 1991px #fff, 125px 1952px #fff,
    1050px 1816px #fff, 278px 114px #fff, 437px 1985px #fff, 1026px 1869px #fff,
    1998px 1776px #fff, 1137px 626px #fff, 256px 1122px #fff, 1985px 1557px #fff,
    532px 762px #fff, 118px 1755px #fff, 496px 1882px #fff, 610px 465px #fff,
    141px 404px #fff, 1963px 591px #fff, 1082px 1115px #fff, 786px 1388px #fff,
    1967px 1850px #fff, 675px 1039px #fff, 424px 1124px #fff, 293px 879px #fff,
    1317px 1679px #fff, 1667px 93px #fff, 1732px 810px #fff, 1121px 1138px #fff,
    6px 1137px #fff, 752px 356px #fff, 789px 457px #fff, 1981px 292px #fff,
    95px 288px #fff, 384px 346px #fff, 1214px 1971px #fff, 1142px 1052px #fff,
    792px 511px #fff, 37px 985px #fff, 693px 846px #fff, 1936px 1416px #fff,
    1222px 1261px #fff, 45px 706px #fff, 1961px 1393px #fff, 602px 118px #fff,
    1224px 1143px #fff, 1944px 1291px #fff, 955px 721px #fff, 36px 743px #fff,
    299px 1624px #fff, 927px 904px #fff, 1619px 1291px #fff, 1783px 1232px #fff,
    694px 1447px #fff, 342px 998px #fff, 704px 1775px #fff, 913px 802px #fff,
    979px 342px #fff, 1569px 1108px #fff, 1047px 797px #fff, 25px 659px #fff,
    1679px 265px #fff, 1430px 1704px #fff, 266px 803px #fff, 1911px 1632px #fff,
    1707px 1950px #fff, 1671px 1550px #fff, 570px 1031px #fff, 1955px 185px #fff,
    132px 728px #fff, 531px 539px #fff, 481px 1631px #fff, 1623px 273px #fff,
    643px 1552px #fff, 1854px 1870px #fff, 249px 124px #fff, 1294px 1167px #fff,
    162px 681px #fff, 908px 29px #fff, 1183px 1488px #fff, 32px 1902px #fff,
    94px 353px #fff, 611px 1094px #fff, 1628px 529px #fff, 683px 27px #fff,
    368px 368px #fff, 1096px 614px #fff, 576px 1690px #fff, 1014px 712px #fff,
    356px 1805px #fff, 1851px 1941px #fff, 387px 462px #fff, 1637px 867px #fff,
    1087px 1853px #fff, 74px 1133px #fff, 1004px 95px #fff, 30px 699px #fff,
    1726px 1976px #fff, 1497px 921px #fff, 1929px 1909px #fff, 1219px 704px #fff,
    1810px 629px #fff, 940px 753px #fff, 1104px 79px #fff, 1819px 1388px #fff,
    1413px 1954px #fff, 1927px 1656px #fff, 1177px 928px #fff, 260px 1992px #fff,
    1600px 845px #fff, 696px 839px #fff, 1174px 937px #fff, 1728px 1127px #fff,
    798px 475px #fff, 880px 1861px #fff, 612px 1541px #fff, 1859px 609px #fff,
    430px 688px #fff, 1725px 1330px #fff, 627px 196px #fff, 998px 1678px #fff,
    21px 40px #fff, 1115px 406px #fff, 1553px 606px #fff, 1309px 324px #fff,
    401px 1003px #fff, 1237px 1209px #fff, 1138px 1282px #fff, 506px 1834px #fff,
    356px 1282px #fff, 1922px 242px #fff, 932px 69px #fff, 1247px 1620px #fff,
    611px 1906px #fff, 779px 874px #fff, 679px 686px #fff, 1359px 1262px #fff,
    1508px 1000px #fff, 1719px 1400px #fff, 1737px 1282px #fff,
    1350px 1803px #fff, 1884px 1478px #fff, 1976px 47px #fff, 838px 1113px #fff,
    600px 1409px #fff, 426px 1534px #fff, 1592px 11px #fff, 1114px 1458px #fff,
    414px 176px #fff, 274px 381px #fff, 794px 286px #fff, 605px 691px #fff,
    1014px 1873px #fff, 138px 897px #fff, 89px 1680px #fff, 904px 1133px #fff,
    134px 284px #fff, 653px 1032px #fff, 260px 538px #fff, 1035px 1426px #fff,
    40px 1027px #fff, 1260px 1882px #fff, 1344px 1074px #fff, 108px 371px #fff,
    1807px 1212px #fff, 1628px 1479px #fff, 145px 74px #fff, 1312px 121px #fff,
    789px 927px #fff, 1399px 1916px #fff, 360px 354px #fff, 1187px 1305px #fff,
    546px 1868px #fff, 547px 51px #fff, 1397px 1064px #fff, 20px 1086px #fff,
    1693px 946px #fff, 1424px 1076px #fff, 133px 404px #fff, 737px 1317px #fff,
    1905px 1996px #fff, 1440px 201px #fff, 711px 139px #fff, 299px 387px #fff,
    992px 664px #fff, 170px 310px #fff, 290px 1727px #fff, 241px 1911px #fff,
    329px 1941px #fff, 1536px 677px #fff, 1429px 1721px #fff, 1719px 99px #fff,
    1423px 865px #fff, 622px 1311px #fff, 956px 671px #fff, 1874px 1745px #fff,
    1824px 720px #fff, 748px 400px #fff, 1658px 916px #fff, 209px 985px #fff,
    187px 459px #fff, 1962px 697px #fff, 1905px 280px #fff, 596px 707px #fff,
    1914px 1017px #fff, 1302px 544px #fff, 1548px 1673px #fff,
    1083px 1522px #fff, 548px 1998px #fff, 160px 1509px #fff, 764px 1839px #fff,
    1681px 979px #fff, 1063px 309px #fff, 266px 623px #fff, 226px 12px #fff,
    1065px 1740px #fff, 1667px 1380px #fff, 1890px 1689px #fff,
    512px 1987px #fff, 1428px 1211px #fff, 1201px 892px #fff, 1007px 20px #fff,
    1227px 1040px #fff, 1866px 790px #fff, 1514px 278px #fff, 1274px 919px #fff,
    341px 1290px #fff, 420px 461px #fff, 734px 1295px #fff, 907px 1981px #fff,
    866px 1465px #fff, 358px 730px #fff, 1086px 14px #fff, 180px 1612px #fff,
    1702px 708px #fff, 1875px 1052px #fff, 507px 745px #fff, 496px 1041px #fff,
    429px 741px #fff, 1448px 512px #fff, 1100px 129px #fff, 488px 789px #fff,
    1025px 1964px #fff, 340px 1516px #fff, 1481px 1420px #fff,
    1054px 1960px #fff, 1833px 1106px #fff, 1082px 1052px #fff, 81px 1269px #fff,
    244px 1150px #fff, 209px 421px #fff, 592px 87px #fff, 728px 405px #fff,
    604px 1482px #fff, 1357px 1028px #fff, 37px 80px #fff, 1013px 37px #fff,
    15px 1347px #fff, 194px 1548px #fff, 722px 846px #fff, 1974px 1548px #fff,
    1449px 1539px #fff, 784px 467px #fff, 568px 381px #fff, 807px 921px #fff,
    370px 1500px #fff, 1236px 303px #fff, 1496px 155px #fff, 1093px 1956px #fff,
    151px 1040px #fff, 734px 1898px #fff, 832px 1220px #fff, 345px 248px #fff,
    851px 1672px #fff, 403px 143px #fff, 1296px 1312px #fff, 1127px 1164px #fff,
    634px 1356px #fff, 134px 1613px #fff, 762px 995px #fff, 538px 768px #fff,
    82px 1881px #fff, 164px 1172px #fff, 1219px 1271px #fff, 352px 1246px #fff,
    1316px 694px #fff, 950px 1053px #fff, 638px 464px #fff, 1977px 1515px #fff,
    1384px 1064px #fff, 1433px 245px #fff, 806px 48px #fff, 1886px 476px #fff,
    1950px 196px #fff, 1689px 695px #fff, 506px 1616px #fff, 1282px 3px #fff,
    246px 492px #fff, 784px 643px #fff, 1393px 1043px #fff, 1294px 628px #fff,
    1807px 1932px #fff, 1792px 821px #fff, 976px 1110px #fff, 398px 1917px #fff,
    722px 1730px #fff, 785px 301px #fff, 345px 28px #fff, 1552px 1289px #fff,
    1165px 1216px #fff, 541px 1883px #fff, 791px 893px #fff, 159px 204px #fff,
    843px 1164px #fff, 416px 195px #fff, 1799px 215px #fff, 377px 1884px #fff,
    1919px 1770px #fff, 663px 766px #fff, 770px 191px #fff, 691px 1308px #fff,
    1748px 419px #fff, 1439px 1310px #fff, 957px 1491px #fff, 276px 1838px #fff,
    1822px 1519px #fff, 1736px 1003px #fff, 189px 1365px #fff, 862px 1804px #fff,
    1360px 1624px #fff, 323px 1664px #fff, 205px 27px #fff, 139px 1782px #fff,
    96px 339px #fff, 1907px 326px #fff, 418px 485px #fff, 1833px 1464px #fff,
    698px 1016px #fff, 520px 1272px #fff, 1231px 148px #fff, 1718px 1524px #fff,
    1956px 1886px #fff, 487px 238px #fff, 920px 1979px #fff, 1809px 1085px #fff,
    295px 1585px #fff, 569px 1339px #fff, 979px 1758px #fff, 69px 546px #fff,
    1220px 1289px #fff, 596px 1711px #fff, 121px 405px #fff, 1242px 768px #fff,
    611px 1199px #fff, 1713px 1603px #fff, 1031px 1865px #fff,
    1464px 1222px #fff, 156px 1157px #fff, 1143px 1078px #fff, 1843px 571px #fff,
    1325px 277px #fff, 1634px 1899px #fff, 1134px 1294px #fff, 71px 704px #fff,
    777px 1314px #fff, 131px 393px #fff, 403px 1950px #fff, 988px 1335px #fff,
    199px 1643px #fff, 1893px 651px #fff, 458px 1808px #fff, 209px 1094px #fff,
    593px 1249px #fff, 25px 1109px #fff, 1515px 1442px #fff, 982px 70px #fff,
    351px 479px #fff, 185px 102px #fff, 1598px 265px #fff, 23px 983px #fff,
    432px 1089px #fff, 910px 1888px #fff, 219px 113px #fff, 1978px 509px #fff,
    922px 162px #fff, 1093px 1493px #fff, 1298px 1174px #fff, 1096px 230px #fff,
    608px 510px #fff, 889px 715px #fff, 934px 1502px #fff, 1394px 1210px #fff,
    283px 961px #fff, 1265px 1024px #fff, 26px 1625px #fff, 1896px 1061px #fff,
    934px 901px #fff, 1469px 815px #fff, 1852px 1653px #fff, 1119px 1534px #fff,
    537px 14px #fff, 1889px 757px #fff, 621px 136px #fff, 476px 1870px #fff,
    1422px 1125px #fff, 1357px 1301px #fff, 1378px 1505px #fff, 624px 772px #fff,
    401px 1899px #fff, 1671px 626px #fff, 1546px 1288px #fff, 1084px 78px #fff,
    468px 85px #fff, 1567px 254px #fff, 231px 1894px #fff, 1991px 916px #fff,
    1523px 515px #fff, 1052px 884px #fff, 1846px 528px #fff, 41px 833px #fff,
    1879px 780px #fff, 1852px 1951px #fff, 1517px 1669px #fff, 143px 351px #fff,
    1586px 244px #fff, 633px 881px #fff, 1320px 1368px #fff, 629px 331px #fff,
    1642px 393px #fff, 1626px 257px #fff, 967px 949px #fff, 848px 1890px #fff,
    1297px 125px #fff, 467px 1124px #fff, 1464px 1760px #fff, 1467px 1449px #fff,
    1476px 1609px #fff, 801px 630px #fff, 801px 802px #fff, 431px 1482px #fff,
    567px 1865px #fff, 1517px 828px #fff, 1285px 1267px #fff, 1040px 1118px #fff,
    1661px 164px #fff, 539px 848px #fff, 3px 1183px #fff, 247px 327px #fff,
    739px 1977px #fff, 1390px 1984px #fff, 1718px 83px #fff, 153px 66px #fff,
    1787px 1230px #fff, 80px 152px #fff, 1879px 250px #fff, 1437px 1684px #fff,
    156px 666px #fff, 1415px 1956px #fff, 387px 47px #fff, 1117px 625px #fff,
    1093px 1570px #fff, 1449px 1566px #fff, 1321px 584px #fff, 358px 1580px #fff;
  animation: animStar 50s linear infinite;
}
#stars:after {
  content: ' ';
  position: absolute;
  top: 2000px;
  width: 1px;
  height: 1px;
  background: transparent;
  box-shadow: 581px 1751px #fff, 1686px 604px #fff, 1463px 830px #fff,
    1666px 1570px #fff, 1427px 130px #fff, 1962px 300px #fff, 1428px 659px #fff,
    730px 1722px #fff, 1332px 1010px #fff, 1225px 698px #fff, 1088px 139px #fff,
    1114px 1022px #fff, 827px 1034px #fff, 1536px 1129px #fff, 311px 1833px #fff,
    947px 450px #fff, 1324px 306px #fff, 1333px 608px #fff, 805px 1669px #fff,
    1861px 86px #fff, 168px 457px #fff, 1683px 1054px #fff, 1280px 173px #fff,
    764px 1512px #fff, 1717px 261px #fff, 1394px 139px #fff, 1736px 609px #fff,
    1052px 165px #fff, 1120px 817px #fff, 172px 1659px #fff, 931px 1068px #fff,
    1851px 1556px #fff, 1884px 862px #fff, 1343px 1376px #fff, 138px 1169px #fff,
    1592px 584px #fff, 975px 141px #fff, 1216px 933px #fff, 879px 1933px #fff,
    320px 2000px #fff, 3px 1212px #fff, 1643px 1884px #fff, 700px 950px #fff,
    417px 542px #fff, 1663px 1246px #fff, 967px 1831px #fff, 1162px 928px #fff,
    983px 770px #fff, 1000px 1544px #fff, 23px 1081px #fff, 1925px 787px #fff,
    309px 612px #fff, 1350px 1084px #fff, 1736px 1107px #fff, 605px 485px #fff,
    1067px 481px #fff, 1176px 1506px #fff, 95px 685px #fff, 1073px 30px #fff,
    1085px 1610px #fff, 304px 1339px #fff, 1185px 1641px #fff,
    1368px 1460px #fff, 1006px 663px #fff, 1393px 739px #fff, 1705px 1361px #fff,
    1330px 28px #fff, 517px 665px #fff, 527px 1516px #fff, 947px 1777px #fff,
    1627px 1720px #fff, 956px 346px #fff, 1065px 1988px #fff, 1385px 725px #fff,
    1832px 602px #fff, 893px 421px #fff, 605px 1928px #fff, 1065px 1823px #fff,
    1794px 1641px #fff, 1857px 1369px #fff, 981px 1279px #fff, 793px 1843px #fff,
    688px 424px #fff, 1492px 502px #fff, 880px 92px #fff, 726px 1322px #fff,
    1751px 476px #fff, 531px 1133px #fff, 479px 666px #fff, 1581px 1340px #fff,
    1056px 93px #fff, 513px 416px #fff, 1162px 985px #fff, 995px 791px #fff,
    1890px 1409px #fff, 59px 83px #fff, 851px 1777px #fff, 1251px 47px #fff,
    1450px 1335px #fff, 400px 1159px #fff, 1144px 1044px #fff,
    1238px 1940px #fff, 1597px 842px #fff, 880px 738px #fff, 973px 1570px #fff,
    148px 1650px #fff, 502px 1937px #fff, 1655px 1603px #fff, 305px 1528px #fff,
    1914px 343px #fff, 440px 44px #fff, 85px 200px #fff, 951px 1520px #fff,
    221px 1577px #fff, 14px 1940px #fff, 368px 728px #fff, 280px 1189px #fff,
    70px 701px #fff, 1951px 1351px #fff, 644px 1747px #fff, 1308px 1341px #fff,
    1958px 425px #fff, 1566px 777px #fff, 1403px 950px #fff, 1693px 1957px #fff,
    720px 1446px #fff, 1734px 198px #fff, 20px 1283px #fff, 1442px 735px #fff,
    963px 1194px #fff, 1554px 1641px #fff, 424px 417px #fff, 724px 965px #fff,
    1104px 269px #fff, 1927px 693px #fff, 124px 1611px #fff, 1524px 1869px #fff,
    13px 1669px #fff, 85px 1820px #fff, 1519px 1585px #fff, 204px 956px #fff,
    1715px 597px #fff, 224px 1375px #fff, 656px 118px #fff, 1307px 203px #fff,
    591px 1008px #fff, 185px 1871px #fff, 1293px 264px #fff, 269px 83px #fff,
    1929px 910px #fff, 900px 1117px #fff, 752px 965px #fff, 678px 961px #fff,
    885px 1945px #fff, 1410px 325px #fff, 1131px 1453px #fff, 1135px 1126px #fff,
    1408px 1424px #fff, 1872px 320px #fff, 1021px 536px #fff, 832px 1492px #fff,
    1329px 1447px #fff, 522px 1017px #fff, 839px 1085px #fff, 314px 1531px #fff,
    1077px 909px #fff, 1215px 778px #fff, 1659px 1350px #fff, 844px 79px #fff,
    1248px 705px #fff, 1917px 1059px #fff, 82px 1779px #fff, 358px 1625px #fff,
    317px 1440px #fff, 52px 277px #fff, 1508px 532px #fff, 975px 389px #fff,
    764px 158px #fff, 1711px 1235px #fff, 1743px 28px #fff, 175px 1373px #fff,
    36px 372px #fff, 1138px 262px #fff, 1374px 1791px #fff, 685px 1448px #fff,
    1799px 798px #fff, 320px 52px #fff, 465px 1088px #fff, 157px 372px #fff,
    1082px 171px #fff, 188px 1109px #fff, 1743px 996px #fff, 1347px 883px #fff,
    1713px 171px #fff, 1904px 676px #fff, 781px 7px #fff, 1213px 490px #fff,
    1395px 1956px #fff, 655px 124px #fff, 1260px 1894px #fff, 1001px 1605px #fff,
    646px 1077px #fff, 78px 410px #fff, 457px 1974px #fff, 606px 1264px #fff,
    1150px 1928px #fff, 1906px 412px #fff, 1880px 105px #fff, 522px 1467px #fff,
    19px 1540px #fff, 755px 1721px #fff, 679px 81px #fff, 774px 1718px #fff,
    217px 258px #fff, 417px 471px #fff, 1944px 797px #fff, 1609px 619px #fff,
    1372px 948px #fff, 1452px 31px #fff, 868px 261px #fff, 197px 1620px #fff,
    1436px 1844px #fff, 389px 881px #fff, 1718px 1143px #fff, 689px 584px #fff,
    1328px 1099px #fff, 1560px 158px #fff, 731px 1671px #fff, 344px 159px #fff,
    1031px 1573px #fff, 1681px 328px #fff, 1226px 1673px #fff, 293px 643px #fff,
    642px 195px #fff, 984px 1001px #fff, 152px 211px #fff, 841px 1455px #fff,
    946px 1692px #fff, 101px 1655px #fff, 1310px 1991px #fff, 125px 1952px #fff,
    1050px 1816px #fff, 278px 114px #fff, 437px 1985px #fff, 1026px 1869px #fff,
    1998px 1776px #fff, 1137px 626px #fff, 256px 1122px #fff, 1985px 1557px #fff,
    532px 762px #fff, 118px 1755px #fff, 496px 1882px #fff, 610px 465px #fff,
    141px 404px #fff, 1963px 591px #fff, 1082px 1115px #fff, 786px 1388px #fff,
    1967px 1850px #fff, 675px 1039px #fff, 424px 1124px #fff, 293px 879px #fff,
    1317px 1679px #fff, 1667px 93px #fff, 1732px 810px #fff, 1121px 1138px #fff,
    6px 1137px #fff, 752px 356px #fff, 789px 457px #fff, 1981px 292px #fff,
    95px 288px #fff, 384px 346px #fff, 1214px 1971px #fff, 1142px 1052px #fff,
    792px 511px #fff, 37px 985px #fff, 693px 846px #fff, 1936px 1416px #fff,
    1222px 1261px #fff, 45px 706px #fff, 1961px 1393px #fff, 602px 118px #fff,
    1224px 1143px #fff, 1944px 1291px #fff, 955px 721px #fff, 36px 743px #fff,
    299px 1624px #fff, 927px 904px #fff, 1619px 1291px #fff, 1783px 1232px #fff,
    694px 1447px #fff, 342px 998px #fff, 704px 1775px #fff, 913px 802px #fff,
    979px 342px #fff, 1569px 1108px #fff, 1047px 797px #fff, 25px 659px #fff,
    1679px 265px #fff, 1430px 1704px #fff, 266px 803px #fff, 1911px 1632px #fff,
    1707px 1950px #fff, 1671px 1550px #fff, 570px 1031px #fff, 1955px 185px #fff,
    132px 728px #fff, 531px 539px #fff, 481px 1631px #fff, 1623px 273px #fff,
    643px 1552px #fff, 1854px 1870px #fff, 249px 124px #fff, 1294px 1167px #fff,
    162px 681px #fff, 908px 29px #fff, 1183px 1488px #fff, 32px 1902px #fff,
    94px 353px #fff, 611px 1094px #fff, 1628px 529px #fff, 683px 27px #fff,
    368px 368px #fff, 1096px 614px #fff, 576px 1690px #fff, 1014px 712px #fff,
    356px 1805px #fff, 1851px 1941px #fff, 387px 462px #fff, 1637px 867px #fff,
    1087px 1853px #fff, 74px 1133px #fff, 1004px 95px #fff, 30px 699px #fff,
    1726px 1976px #fff, 1497px 921px #fff, 1929px 1909px #fff, 1219px 704px #fff,
    1810px 629px #fff, 940px 753px #fff, 1104px 79px #fff, 1819px 1388px #fff,
    1413px 1954px #fff, 1927px 1656px #fff, 1177px 928px #fff, 260px 1992px #fff,
    1600px 845px #fff, 696px 839px #fff, 1174px 937px #fff, 1728px 1127px #fff,
    798px 475px #fff, 880px 1861px #fff, 612px 1541px #fff, 1859px 609px #fff,
    430px 688px #fff, 1725px 1330px #fff, 627px 196px #fff, 998px 1678px #fff,
    21px 40px #fff, 1115px 406px #fff, 1553px 606px #fff, 1309px 324px #fff,
    401px 1003px #fff, 1237px 1209px #fff, 1138px 1282px #fff, 506px 1834px #fff,
    356px 1282px #fff, 1922px 242px #fff, 932px 69px #fff, 1247px 1620px #fff,
    611px 1906px #fff, 779px 874px #fff, 679px 686px #fff, 1359px 1262px #fff,
    1508px 1000px #fff, 1719px 1400px #fff, 1737px 1282px #fff,
    1350px 1803px #fff, 1884px 1478px #fff, 1976px 47px #fff, 838px 1113px #fff,
    600px 1409px #fff, 426px 1534px #fff, 1592px 11px #fff, 1114px 1458px #fff,
    414px 176px #fff, 274px 381px #fff, 794px 286px #fff, 605px 691px #fff,
    1014px 1873px #fff, 138px 897px #fff, 89px 1680px #fff, 904px 1133px #fff,
    134px 284px #fff, 653px 1032px #fff, 260px 538px #fff, 1035px 1426px #fff,
    40px 1027px #fff, 1260px 1882px #fff, 1344px 1074px #fff, 108px 371px #fff,
    1807px 1212px #fff, 1628px 1479px #fff, 145px 74px #fff, 1312px 121px #fff,
    789px 927px #fff, 1399px 1916px #fff, 360px 354px #fff, 1187px 1305px #fff,
    546px 1868px #fff, 547px 51px #fff, 1397px 1064px #fff, 20px 1086px #fff,
    1693px 946px #fff, 1424px 1076px #fff, 133px 404px #fff, 737px 1317px #fff,
    1905px 1996px #fff, 1440px 201px #fff, 711px 139px #fff, 299px 387px #fff,
    992px 664px #fff, 170px 310px #fff, 290px 1727px #fff, 241px 1911px #fff,
    329px 1941px #fff, 1536px 677px #fff, 1429px 1721px #fff, 1719px 99px #fff,
    1423px 865px #fff, 622px 1311px #fff, 956px 671px #fff, 1874px 1745px #fff,
    1824px 720px #fff, 748px 400px #fff, 1658px 916px #fff, 209px 985px #fff,
    187px 459px #fff, 1962px 697px #fff, 1905px 280px #fff, 596px 707px #fff,
    1914px 1017px #fff, 1302px 544px #fff, 1548px 1673px #fff,
    1083px 1522px #fff, 548px 1998px #fff, 160px 1509px #fff, 764px 1839px #fff,
    1681px 979px #fff, 1063px 309px #fff, 266px 623px #fff, 226px 12px #fff,
    1065px 1740px #fff, 1667px 1380px #fff, 1890px 1689px #fff,
    512px 1987px #fff, 1428px 1211px #fff, 1201px 892px #fff, 1007px 20px #fff,
    1227px 1040px #fff, 1866px 790px #fff, 1514px 278px #fff, 1274px 919px #fff,
    341px 1290px #fff, 420px 461px #fff, 734px 1295px #fff, 907px 1981px #fff,
    866px 1465px #fff, 358px 730px #fff, 1086px 14px #fff, 180px 1612px #fff,
    1702px 708px #fff, 1875px 1052px #fff, 507px 745px #fff, 496px 1041px #fff,
    429px 741px #fff, 1448px 512px #fff, 1100px 129px #fff, 488px 789px #fff,
    1025px 1964px #fff, 340px 1516px #fff, 1481px 1420px #fff,
    1054px 1960px #fff, 1833px 1106px #fff, 1082px 1052px #fff, 81px 1269px #fff,
    244px 1150px #fff, 209px 421px #fff, 592px 87px #fff, 728px 405px #fff,
    604px 1482px #fff, 1357px 1028px #fff, 37px 80px #fff, 1013px 37px #fff,
    15px 1347px #fff, 194px 1548px #fff, 722px 846px #fff, 1974px 1548px #fff,
    1449px 1539px #fff, 784px 467px #fff, 568px 381px #fff, 807px 921px #fff,
    370px 1500px #fff, 1236px 303px #fff, 1496px 155px #fff, 1093px 1956px #fff,
    151px 1040px #fff, 734px 1898px #fff, 832px 1220px #fff, 345px 248px #fff,
    851px 1672px #fff, 403px 143px #fff, 1296px 1312px #fff, 1127px 1164px #fff,
    634px 1356px #fff, 134px 1613px #fff, 762px 995px #fff, 538px 768px #fff,
    82px 1881px #fff, 164px 1172px #fff, 1219px 1271px #fff, 352px 1246px #fff,
    1316px 694px #fff, 950px 1053px #fff, 638px 464px #fff, 1977px 1515px #fff,
    1384px 1064px #fff, 1433px 245px #fff, 806px 48px #fff, 1886px 476px #fff,
    1950px 196px #fff, 1689px 695px #fff, 506px 1616px #fff, 1282px 3px #fff,
    246px 492px #fff, 784px 643px #fff, 1393px 1043px #fff, 1294px 628px #fff,
    1807px 1932px #fff, 1792px 821px #fff, 976px 1110px #fff, 398px 1917px #fff,
    722px 1730px #fff, 785px 301px #fff, 345px 28px #fff, 1552px 1289px #fff,
    1165px 1216px #fff, 541px 1883px #fff, 791px 893px #fff, 159px 204px #fff,
    843px 1164px #fff, 416px 195px #fff, 1799px 215px #fff, 377px 1884px #fff,
    1919px 1770px #fff, 663px 766px #fff, 770px 191px #fff, 691px 1308px #fff,
    1748px 419px #fff, 1439px 1310px #fff, 957px 1491px #fff, 276px 1838px #fff,
    1822px 1519px #fff, 1736px 1003px #fff, 189px 1365px #fff, 862px 1804px #fff,
    1360px 1624px #fff, 323px 1664px #fff, 205px 27px #fff, 139px 1782px #fff,
    96px 339px #fff, 1907px 326px #fff, 418px 485px #fff, 1833px 1464px #fff,
    698px 1016px #fff, 520px 1272px #fff, 1231px 148px #fff, 1718px 1524px #fff,
    1956px 1886px #fff, 487px 238px #fff, 920px 1979px #fff, 1809px 1085px #fff,
    295px 1585px #fff, 569px 1339px #fff, 979px 1758px #fff, 69px 546px #fff,
    1220px 1289px #fff, 596px 1711px #fff, 121px 405px #fff, 1242px 768px #fff,
    611px 1199px #fff, 1713px 1603px #fff, 1031px 1865px #fff,
    1464px 1222px #fff, 156px 1157px #fff, 1143px 1078px #fff, 1843px 571px #fff,
    1325px 277px #fff, 1634px 1899px #fff, 1134px 1294px #fff, 71px 704px #fff,
    777px 1314px #fff, 131px 393px #fff, 403px 1950px #fff, 988px 1335px #fff,
    199px 1643px #fff, 1893px 651px #fff, 458px 1808px #fff, 209px 1094px #fff,
    593px 1249px #fff, 25px 1109px #fff, 1515px 1442px #fff, 982px 70px #fff,
    351px 479px #fff, 185px 102px #fff, 1598px 265px #fff, 23px 983px #fff,
    432px 1089px #fff, 910px 1888px #fff, 219px 113px #fff, 1978px 509px #fff,
    922px 162px #fff, 1093px 1493px #fff, 1298px 1174px #fff, 1096px 230px #fff,
    608px 510px #fff, 889px 715px #fff, 934px 1502px #fff, 1394px 1210px #fff,
    283px 961px #fff, 1265px 1024px #fff, 26px 1625px #fff, 1896px 1061px #fff,
    934px 901px #fff, 1469px 815px #fff, 1852px 1653px #fff, 1119px 1534px #fff,
    537px 14px #fff, 1889px 757px #fff, 621px 136px #fff, 476px 1870px #fff,
    1422px 1125px #fff, 1357px 1301px #fff, 1378px 1505px #fff, 624px 772px #fff,
    401px 1899px #fff, 1671px 626px #fff, 1546px 1288px #fff, 1084px 78px #fff,
    468px 85px #fff, 1567px 254px #fff, 231px 1894px #fff, 1991px 916px #fff,
    1523px 515px #fff, 1052px 884px #fff, 1846px 528px #fff, 41px 833px #fff,
    1879px 780px #fff, 1852px 1951px #fff, 1517px 1669px #fff, 143px 351px #fff,
    1586px 244px #fff, 633px 881px #fff, 1320px 1368px #fff, 629px 331px #fff,
    1642px 393px #fff, 1626px 257px #fff, 967px 949px #fff, 848px 1890px #fff,
    1297px 125px #fff, 467px 1124px #fff, 1464px 1760px #fff, 1467px 1449px #fff,
    1476px 1609px #fff, 801px 630px #fff, 801px 802px #fff, 431px 1482px #fff,
    567px 1865px #fff, 1517px 828px #fff, 1285px 1267px #fff, 1040px 1118px #fff,
    1661px 164px #fff, 539px 848px #fff, 3px 1183px #fff, 247px 327px #fff,
    739px 1977px #fff, 1390px 1984px #fff, 1718px 83px #fff, 153px 66px #fff,
    1787px 1230px #fff, 80px 152px #fff, 1879px 250px #fff, 1437px 1684px #fff,
    156px 666px #fff, 1415px 1956px #fff, 387px 47px #fff, 1117px 625px #fff,
    1093px 1570px #fff, 1449px 1566px #fff, 1321px 584px #fff, 358px 1580px #fff;
}

#stars2 {
  width: 2px;
  height: 2px;
  background: transparent;
  box-shadow: 1803px 1982px #fff, 419px 1311px #fff, 1116px 1335px #fff,
    393px 1031px #fff, 646px 1948px #fff, 312px 1389px #fff, 749px 1283px #fff,
    465px 1105px #fff, 1154px 1440px #fff, 1455px 93px #fff, 147px 493px #fff,
    670px 1385px #fff, 449px 1547px #fff, 679px 819px #fff, 549px 807px #fff,
    640px 1891px #fff, 486px 1179px #fff, 55px 19px #fff, 1309px 938px #fff,
    378px 1780px #fff, 380px 1949px #fff, 1661px 1244px #fff, 685px 902px #fff,
    1345px 935px #fff, 895px 492px #fff, 1695px 433px #fff, 494px 1183px #fff,
    1071px 852px #fff, 1190px 900px #fff, 1949px 401px #fff, 1076px 1200px #fff,
    1255px 1081px #fff, 1360px 1478px #fff, 1230px 1393px #fff,
    1692px 1597px #fff, 519px 1650px #fff, 1329px 1802px #fff, 334px 462px #fff,
    1585px 408px #fff, 593px 1289px #fff, 789px 113px #fff, 587px 1375px #fff,
    646px 738px #fff, 1791px 288px #fff, 1027px 905px #fff, 58px 1746px #fff,
    1142px 1434px #fff, 38px 164px #fff, 1880px 1114px #fff, 1488px 1679px #fff,
    1776px 274px #fff, 1975px 1510px #fff, 1230px 877px #fff, 1437px 274px #fff,
    189px 1613px #fff, 388px 859px #fff, 12px 1299px #fff, 1688px 604px #fff,
    286px 381px #fff, 1229px 476px #fff, 378px 1200px #fff, 1855px 991px #fff,
    1020px 259px #fff, 1770px 375px #fff, 1499px 1492px #fff, 65px 1631px #fff,
    873px 774px #fff, 96px 943px #fff, 502px 195px #fff, 413px 981px #fff,
    340px 22px #fff, 922px 1076px #fff, 1710px 426px #fff, 1773px 264px #fff,
    387px 1733px #fff, 857px 1225px #fff, 465px 986px #fff, 980px 467px #fff,
    205px 1771px #fff, 260px 1217px #fff, 964px 1396px #fff, 1154px 620px #fff,
    1963px 1328px #fff, 444px 1930px #fff, 1225px 1355px #fff,
    1283px 1605px #fff, 1900px 809px #fff, 1121px 1266px #fff, 1939px 838px #fff,
    962px 1718px #fff, 1720px 1499px #fff, 1695px 826px #fff, 128px 1309px #fff,
    395px 559px #fff, 278px 1271px #fff, 593px 1754px #fff, 483px 1980px #fff,
    174px 911px #fff, 1809px 1107px #fff, 315px 38px #fff, 1250px 1318px #fff,
    807px 1885px #fff, 634px 1734px #fff, 1638px 676px #fff, 1886px 1826px #fff,
    272px 233px #fff, 69px 1137px #fff, 1713px 805px #fff, 155px 405px #fff,
    1878px 1430px #fff, 240px 632px #fff, 556px 1964px #fff, 260px 1031px #fff,
    1917px 986px #fff, 612px 999px #fff, 590px 1200px #fff, 1748px 1618px #fff,
    91px 1838px #fff, 1917px 588px #fff, 491px 1011px #fff, 1403px 1038px #fff,
    1237px 349px #fff, 1518px 1803px #fff, 1905px 1643px #fff, 360px 525px #fff,
    51px 362px #fff, 1390px 1487px #fff, 721px 1564px #fff, 931px 1585px #fff,
    1045px 822px #fff, 234px 1969px #fff, 985px 1342px #fff, 881px 321px #fff,
    539px 1800px #fff, 336px 1169px #fff, 1096px 1405px #fff, 564px 1975px #fff,
    1821px 1865px #fff, 1855px 646px #fff, 1210px 371px #fff, 1527px 545px #fff,
    791px 1576px #fff, 832px 126px #fff, 405px 852px #fff, 1925px 1521px #fff,
    1747px 94px #fff, 1803px 1531px #fff, 961px 28px #fff, 45px 755px #fff,
    1198px 1248px #fff, 4px 1871px #fff, 60px 1281px #fff, 1748px 325px #fff,
    1793px 570px #fff, 64px 28px #fff, 390px 243px #fff, 178px 398px #fff,
    1828px 344px #fff, 376px 128px #fff, 99px 1620px #fff, 1134px 1612px #fff,
    1002px 1105px #fff, 1519px 1853px #fff, 124px 482px #fff, 80px 1961px #fff,
    76px 943px #fff, 112px 419px #fff, 777px 309px #fff, 1641px 284px #fff,
    1587px 601px #fff, 1366px 1410px #fff, 1100px 1054px #fff,
    1617px 1507px #fff, 423px 919px #fff, 1639px 676px #fff, 1238px 1133px #fff,
    907px 1545px #fff, 1733px 1106px #fff, 1520px 1335px #fff, 1131px 841px #fff,
    32px 397px #fff, 1935px 1197px #fff, 911px 119px #fff, 1425px 1375px #fff,
    864px 1257px #fff, 37px 1228px #fff, 1781px 1675px #fff, 1938px 70px #fff,
    748px 39px #fff, 331px 1635px #fff, 1317px 545px #fff, 608px 302px #fff,
    1910px 1236px #fff, 983px 1008px #fff, 1219px 1883px #fff,
    1335px 1569px #fff, 1095px 947px #fff, 1118px 678px #fff, 929px 1287px #fff,
    1177px 1430px #fff;
  animation: animStar 100s linear infinite;
}
#stars2:after {
  content: ' ';
  position: absolute;
  top: 2000px;
  width: 2px;
  height: 2px;
  background: transparent;
  box-shadow: 1803px 1982px #fff, 419px 1311px #fff, 1116px 1335px #fff,
    393px 1031px #fff, 646px 1948px #fff, 312px 1389px #fff, 749px 1283px #fff,
    465px 1105px #fff, 1154px 1440px #fff, 1455px 93px #fff, 147px 493px #fff,
    670px 1385px #fff, 449px 1547px #fff, 679px 819px #fff, 549px 807px #fff,
    640px 1891px #fff, 486px 1179px #fff, 55px 19px #fff, 1309px 938px #fff,
    378px 1780px #fff, 380px 1949px #fff, 1661px 1244px #fff, 685px 902px #fff,
    1345px 935px #fff, 895px 492px #fff, 1695px 433px #fff, 494px 1183px #fff,
    1071px 852px #fff, 1190px 900px #fff, 1949px 401px #fff, 1076px 1200px #fff,
    1255px 1081px #fff, 1360px 1478px #fff, 1230px 1393px #fff,
    1692px 1597px #fff, 519px 1650px #fff, 1329px 1802px #fff, 334px 462px #fff,
    1585px 408px #fff, 593px 1289px #fff, 789px 113px #fff, 587px 1375px #fff,
    646px 738px #fff, 1791px 288px #fff, 1027px 905px #fff, 58px 1746px #fff,
    1142px 1434px #fff, 38px 164px #fff, 1880px 1114px #fff, 1488px 1679px #fff,
    1776px 274px #fff, 1975px 1510px #fff, 1230px 877px #fff, 1437px 274px #fff,
    189px 1613px #fff, 388px 859px #fff, 12px 1299px #fff, 1688px 604px #fff,
    286px 381px #fff, 1229px 476px #fff, 378px 1200px #fff, 1855px 991px #fff,
    1020px 259px #fff, 1770px 375px #fff, 1499px 1492px #fff, 65px 1631px #fff,
    873px 774px #fff, 96px 943px #fff, 502px 195px #fff, 413px 981px #fff,
    340px 22px #fff, 922px 1076px #fff, 1710px 426px #fff, 1773px 264px #fff,
    387px 1733px #fff, 857px 1225px #fff, 465px 986px #fff, 980px 467px #fff,
    205px 1771px #fff, 260px 1217px #fff, 964px 1396px #fff, 1154px 620px #fff,
    1963px 1328px #fff, 444px 1930px #fff, 1225px 1355px #fff,
    1283px 1605px #fff, 1900px 809px #fff, 1121px 1266px #fff, 1939px 838px #fff,
    962px 1718px #fff, 1720px 1499px #fff, 1695px 826px #fff, 128px 1309px #fff,
    395px 559px #fff, 278px 1271px #fff, 593px 1754px #fff, 483px 1980px #fff,
    174px 911px #fff, 1809px 1107px #fff, 315px 38px #fff, 1250px 1318px #fff,
    807px 1885px #fff, 634px 1734px #fff, 1638px 676px #fff, 1886px 1826px #fff,
    272px 233px #fff, 69px 1137px #fff, 1713px 805px #fff, 155px 405px #fff,
    1878px 1430px #fff, 240px 632px #fff, 556px 1964px #fff, 260px 1031px #fff,
    1917px 986px #fff, 612px 999px #fff, 590px 1200px #fff, 1748px 1618px #fff,
    91px 1838px #fff, 1917px 588px #fff, 491px 1011px #fff, 1403px 1038px #fff,
    1237px 349px #fff, 1518px 1803px #fff, 1905px 1643px #fff, 360px 525px #fff,
    51px 362px #fff, 1390px 1487px #fff, 721px 1564px #fff, 931px 1585px #fff,
    1045px 822px #fff, 234px 1969px #fff, 985px 1342px #fff, 881px 321px #fff,
    539px 1800px #fff, 336px 1169px #fff, 1096px 1405px #fff, 564px 1975px #fff,
    1821px 1865px #fff, 1855px 646px #fff, 1210px 371px #fff, 1527px 545px #fff,
    791px 1576px #fff, 832px 126px #fff, 405px 852px #fff, 1925px 1521px #fff,
    1747px 94px #fff, 1803px 1531px #fff, 961px 28px #fff, 45px 755px #fff,
    1198px 1248px #fff, 4px 1871px #fff, 60px 1281px #fff, 1748px 325px #fff,
    1793px 570px #fff, 64px 28px #fff, 390px 243px #fff, 178px 398px #fff,
    1828px 344px #fff, 376px 128px #fff, 99px 1620px #fff, 1134px 1612px #fff,
    1002px 1105px #fff, 1519px 1853px #fff, 124px 482px #fff, 80px 1961px #fff,
    76px 943px #fff, 112px 419px #fff, 777px 309px #fff, 1641px 284px #fff,
    1587px 601px #fff, 1366px 1410px #fff, 1100px 1054px #fff,
    1617px 1507px #fff, 423px 919px #fff, 1639px 676px #fff, 1238px 1133px #fff,
    907px 1545px #fff, 1733px 1106px #fff, 1520px 1335px #fff, 1131px 841px #fff,
    32px 397px #fff, 1935px 1197px #fff, 911px 119px #fff, 1425px 1375px #fff,
    864px 1257px #fff, 37px 1228px #fff, 1781px 1675px #fff, 1938px 70px #fff,
    748px 39px #fff, 331px 1635px #fff, 1317px 545px #fff, 608px 302px #fff,
    1910px 1236px #fff, 983px 1008px #fff, 1219px 1883px #fff,
    1335px 1569px #fff, 1095px 947px #fff, 1118px 678px #fff, 929px 1287px #fff,
    1177px 1430px #fff;
}

#stars3 {
  width: 3px;
  height: 3px;
  background: transparent;
  box-shadow: 679px 876px #fff, 1389px 1239px #fff, 110px 978px #fff,
    711px 721px #fff, 598px 656px #fff, 1338px 804px #fff, 1039px 299px #fff,
    1001px 904px #fff, 1144px 333px #fff, 1461px 779px #fff, 472px 1255px #fff,
    573px 879px #fff, 542px 816px #fff, 566px 783px #fff, 1467px 1786px #fff,
    668px 1324px #fff, 210px 887px #fff, 1765px 966px #fff, 436px 1580px #fff,
    1077px 677px #fff, 1480px 1696px #fff, 1902px 764px #fff, 634px 1379px #fff,
    376px 1812px #fff, 1946px 724px #fff, 737px 97px #fff, 1523px 192px #fff,
    191px 1664px #fff, 1671px 218px #fff, 499px 1749px #fff, 1944px 672px #fff,
    1426px 1277px #fff, 686px 188px #fff, 1577px 1636px #fff, 1744px 1792px #fff,
    1055px 11px #fff, 1889px 744px #fff, 260px 1011px #fff, 921px 313px #fff,
    677px 146px #fff, 1707px 1879px #fff, 1735px 830px #fff, 1745px 204px #fff,
    821px 206px #fff, 909px 175px #fff, 1052px 1168px #fff, 1559px 405px #fff,
    272px 407px #fff, 953px 790px #fff, 1155px 633px #fff, 1776px 40px #fff,
    1862px 1609px #fff, 1744px 1495px #fff, 999px 1621px #fff,
    1801px 1263px #fff, 1655px 843px #fff, 858px 1272px #fff, 1969px 1265px #fff,
    763px 1141px #fff, 1351px 523px #fff, 1967px 1400px #fff, 1845px 290px #fff,
    1208px 1641px #fff, 1562px 1551px #fff, 923px 236px #fff, 431px 1420px #fff,
    1115px 1015px #fff, 1823px 1038px #fff, 1362px 827px #fff, 1042px 297px #fff,
    1020px 1329px #fff, 400px 665px #fff, 1586px 1046px #fff, 648px 531px #fff,
    899px 1498px #fff, 292px 1926px #fff, 714px 1540px #fff, 1378px 1455px #fff,
    1108px 716px #fff, 1037px 948px #fff, 349px 1593px #fff, 717px 1454px #fff,
    207px 467px #fff, 1026px 523px #fff, 47px 590px #fff, 1912px 216px #fff,
    1870px 816px #fff, 1549px 1916px #fff, 1891px 1768px #fff,
    1775px 1763px #fff, 1616px 211px #fff, 1794px 1070px #fff,
    1175px 1490px #fff, 1359px 327px #fff, 124px 98px #fff, 1868px 238px #fff,
    1712px 1668px #fff, 275px 386px #fff, 1493px 1356px #fff, 1653px 407px #fff;
  animation: animStar 150s linear infinite;
}
#stars3:after {
  content: ' ';
  position: absolute;
  top: 2000px;
  width: 3px;
  height: 3px;
  background: transparent;
  box-shadow: 679px 876px #fff, 1389px 1239px #fff, 110px 978px #fff,
    711px 721px #fff, 598px 656px #fff, 1338px 804px #fff, 1039px 299px #fff,
    1001px 904px #fff, 1144px 333px #fff, 1461px 779px #fff, 472px 1255px #fff,
    573px 879px #fff, 542px 816px #fff, 566px 783px #fff, 1467px 1786px #fff,
    668px 1324px #fff, 210px 887px #fff, 1765px 966px #fff, 436px 1580px #fff,
    1077px 677px #fff, 1480px 1696px #fff, 1902px 764px #fff, 634px 1379px #fff,
    376px 1812px #fff, 1946px 724px #fff, 737px 97px #fff, 1523px 192px #fff,
    191px 1664px #fff, 1671px 218px #fff, 499px 1749px #fff, 1944px 672px #fff,
    1426px 1277px #fff, 686px 188px #fff, 1577px 1636px #fff, 1744px 1792px #fff,
    1055px 11px #fff, 1889px 744px #fff, 260px 1011px #fff, 921px 313px #fff,
    677px 146px #fff, 1707px 1879px #fff, 1735px 830px #fff, 1745px 204px #fff,
    821px 206px #fff, 909px 175px #fff, 1052px 1168px #fff, 1559px 405px #fff,
    272px 407px #fff, 953px 790px #fff, 1155px 633px #fff, 1776px 40px #fff,
    1862px 1609px #fff, 1744px 1495px #fff, 999px 1621px #fff,
    1801px 1263px #fff, 1655px 843px #fff, 858px 1272px #fff, 1969px 1265px #fff,
    763px 1141px #fff, 1351px 523px #fff, 1967px 1400px #fff, 1845px 290px #fff,
    1208px 1641px #fff, 1562px 1551px #fff, 923px 236px #fff, 431px 1420px #fff,
    1115px 1015px #fff, 1823px 1038px #fff, 1362px 827px #fff, 1042px 297px #fff,
    1020px 1329px #fff, 400px 665px #fff, 1586px 1046px #fff, 648px 531px #fff,
    899px 1498px #fff, 292px 1926px #fff, 714px 1540px #fff, 1378px 1455px #fff,
    1108px 716px #fff, 1037px 948px #fff, 349px 1593px #fff, 717px 1454px #fff,
    207px 467px #fff, 1026px 523px #fff, 47px 590px #fff, 1912px 216px #fff,
    1870px 816px #fff, 1549px 1916px #fff, 1891px 1768px #fff,
    1775px 1763px #fff, 1616px 211px #fff, 1794px 1070px #fff,
    1175px 1490px #fff, 1359px 327px #fff, 124px 98px #fff, 1868px 238px #fff,
    1712px 1668px #fff, 275px 386px #fff, 1493px 1356px #fff, 1653px 407px #fff;
}

@keyframes animStar {
  from {
    transform: translateY(0px);
  }
  to {
    transform: translateY(-2000px);
  }
}

/* ANIMATED STARS BACKGROUND  */

/* PUBLISHER PAGE */
.publisher-monetization-grey {
  background: #f8f8f8 0% 0% no-repeat padding-box;
  overflow: auto;
}
.publisher-monetization-grey h2 {
  max-width: 850px;
  text-align: center;
  font-weight: 600;
  font-size: 2.375rem;
  line-height: 2.9375rem;
  letter-spacing: 0;
  color: #012d6c;
  margin: 60px auto;
}
.publisher-monetization-grey .card {
  margin-top: 80px;
  width: 398px;
  height: 264px;
}
.publisher-monetization-grey .card-img {
  position: absolute;
  height: 160px;
  top: -79px;
}
.publisher-half-content h2 {
  font-size: 1.75rem;
  text-align: left;
}
.hero-section-publisher {
  background: #0071bd;
  color: #fff;
}
.hero-section-publisher h1 {
  color: #fff;
}
.hero-section-publisher p {
  color: #fff;
}
.publisher-monetization-grey {
  background: #f8f8f8 0% 0% no-repeat padding-box;
  overflow: auto;
}
.publisher-monetization-grey h2 {
  max-width: 850px;
  text-align: center;
  font-weight: 600;
  font-size: 2.375rem;
  line-height: 2.9375rem;
  letter-spacing: 0;
  color: #012d6c;
  margin: 60px auto 110px;
}
.publisher-monetization-grey p {
  color: #9b9b9b;
}
.publisher-monetization-grey h3 {
  position: relative;
  top: 25px;
}

/* PUBLISHER TABS */
.publisher-tabs-section h2,
.publisher-tabs-section h3 {
  font-weight: 600;
  font-size: 1.75rem;
  line-height: 1.625rem;
  margin: 60px auto 10px;
  text-align: center;
  max-width: 900px;
  color: #012d6c;
}
.publisher-tabs-section h3 {
  font-size: 1.3rem;
  margin: 0 auto 60px;
}
.publisher-tabs-section .panel h3 {
  font-size: 1.75rem;
  line-height: 1.625rem;
  margin: 60px auto 10px;
  margin-bottom: 0;
  text-align: left;
}
.publisher-tabs-section .panel p {
  line-height: 2.2rem;
}
.panel p.publisher-panel-small {
  font-size: 1rem;
  line-height: 1.5625rem;
}
.warpper {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.tabs {
  text-align: center;
  width: 100%;
  max-width: 1450px;
}
.tab {
  border: 2px solid #99abc4;
  color: #99abc4;
  background: #fff;
  cursor: pointer;
  padding: 25px 0;
  line-height: 1;
  transition: all 0.4s;
  width: 280px;
  margin: 15px auto;
  display: block;
}
.tab:hover {
  border: 2px solid #f9ae3b;
  color: #f9ae3b;
}
.panels {
  background: #fffffff6;
  min-height: 200px;
  max-width: 80%;
  border-radius: 3px;
  overflow: hidden;
  padding: 20px;
  display: flex;
  width: 80%;
  margin: 0 auto;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: stretch;
}
.panel {
  position: relative;
  display: none;
  animation: fadeinPublisher 0.8s;
}
.panel > div {
  width: 100%;
}
@keyframes fadeinPublisher {
  from {
    opacity: 0;
    right: -100px;
  }
  to {
    opacity: 1;
    right: 0;
  }
}

.radio {
  display: none;
}
#one:checked ~ .panels #one-panel,
#two:checked ~ .panels #two-panel,
#three:checked ~ .panels #three-panel {
  display: flex;
}
#one:checked ~ .tabs #one-tab,
#two:checked ~ .tabs #two-tab,
#three:checked ~ .tabs #three-tab {
  background: #f9ae3b;
  color: #fff;
  border: 2px solid #f9ae3b;
}

/* TIMELINE PUBLISHER */
.publisher-timeline-section {
  background: #f8f8f8;
  padding: 40px;
}
.publisher-timeline-section h2 {
  text-align: left;
  font-weight: 600;
  font-size: 2.375rem;
  line-height: 2.9375rem;
  color: #012d6c;
  margin: 40px;
}
.publisher-timeline-section p {
  margin: 40px;
  text-align: left;
  font-weight: 300;
  font-size: 1.3125rem;
  line-height: 1.9375rem;
  color: #9b9b9b;
}
.timeline-container {
  width: 80%;
  padding: 50px 0;
  margin: 50px auto;
  position: relative;
  overflow: hidden;
}
.timeline-container:before {
  left: 8px;
  width: 2px;
  content: '';
  position: absolute;
  margin-left: -1px;
  width: 2px;
  height: 100%;
  background: #cce2f1;
  z-index: 1;
}

.timeline-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -moz-box-pack: justify;
  justify-content: space-between;
  clear: both;
}

.marker {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 2px solid #f5f7fa;
  background: #cce2f1;
  margin-top: 10px;
  z-index: 9999;
}

.timeline-content {
  width: 95%;
  padding: 0 15px;
  color: #666;
}

.timeline-content h3 {
  background: #ffffff;
  box-shadow: 0px 15px 30px #cce2f166;
  max-width: 280px;
  padding: 20px;
  position: relative;
  bottom: 20px;
  text-align: center;
  line-height: 1.625rem;
  letter-spacing: 0;
  color: #012d6c;
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 1.1rem;
  font-weight: 600;
}
.timeline-content h3:after {
  content: ' ';
  position: absolute;
  border-top: 10px solid transparent;
  border-right: none;
  border-left: 15px solid #ffffff;
  border-bottom: 10px solid transparent;
  left: -15px;
  top: 24px;
  transform: rotate(180deg);
}

.timeline-content span {
  font-size: 0.9375rem;
  color: #a4a4a4;
}

.timeline-content p {
  font-size: 0.875rem;
  line-height: 1.5em;
  word-spacing: 1px;
  color: #888;
}

.timeline-block {
  width: 100%;
  margin-bottom: 30px;
}
.timeline-block-right {
  float: none;
}

.timeline-block-left {
  float: none;
  direction: ltr;
}
.publisher-case-studies h2 {
  text-align: center;
  font-size: 2.375rem;
  color: #012d6c;
  font-weight: 600;
  line-height: 2.9375rem;
  margin: 40px;
}
.publisher-case-studies p {
  max-width: 845px;
  text-align: center;
  font-size: 1.3125rem;
  color: #9b9b9b;
  font-weight: 300;
  line-height: 1.9375rem;
  margin: 40px auto;
}
.publisher-case-studies .container .card {
  max-width: 360px;
  margin: 0 auto;
  text-align: center;
  margin-bottom: 40px;
}
.publisher-case-studies .container .card img {
  width: 100%;
}
.publisher-case-studies .container .card h3 {
  color: #012d6c;
  opacity: 1;
  font-weight: 600;
  font-size: 1.3125rem;
  line-height: 1.625rem;
}
.publisher-case-studies .container .card a {
  border: 3px solid #f9ae3b;
  border-radius: 15px;
  width: 260px;
  padding: 15px 0;
  display: block;
  font-weight: 600;
  color: #f9ae3b;
  margin: 30px auto;
}
.publisher-reasons-section {
  background: #0071bd;
  overflow: hidden;
  padding: 20px;
  position: relative;
  /* padding-bottom: 0; */
  text-align: left;
}
.publisher-reasons-section h2 {
  text-align: center;
  font-size: 2.375rem;
  color: #fff;
  font-weight: 600;
  line-height: 2.9375rem;
  margin: 40px;
}
.publisher-reasons-section h2,
.publisher-reasons-section p {
  color: #fff;
}
.reasons-list-container,
.reasons-list-container-publisher {
  margin: 100px 0;
  overflow: visible;
}
.reasons-list-container-publisher:after {
  content: '';
  position: absolute;
  z-index: 1;
  bottom: 0;
  height: 100%;
  right: 0;
  pointer-events: none;
  background-image: linear-gradient(
    to right,
    rgba(255, 255, 255, 0),
    rgb(0, 113, 189) 90%
  );
  width: 15%;
}
.reasons-list {
  position: relative;
}
.reasons-list h5 {
  margin: 20px 0;
  text-align: left;
  font-weight: 600;
  color: #fff;
  font-size: 1.3125rem;
  line-height: 1.625rem;
}
.reasons-list-container .call-action {
  position: relative;
  left: 50%;
  transform: translate(-50%, 0%);
}
.publisher-reasons-section .reasons-list p {
  text-align: left;
  margin: 0;
  max-width: 260px;
  font-weight: 300;
  font-size: 1rem;
  line-height: 1.1875rem;
  text-align: left;
  color: #99c6e4;
}
.reasons-list-hidden {
  display: none;
}
.publisher-reasons-section ul li {
  display: inline-block;
}
.reasons-arrow {
  color: #012d6c;
  font-weight: 500;
  user-select: none;
  width: 35px;
  height: 35px;
  text-align: center;
  font-size: 34px;
  box-shadow: 0px 15px 30px #012d6c26;
  cursor: pointer;
  padding-left: 4px;
  background: #f9ae3b;
  border-radius: 50%;
  transition: all 0.4s;
  bottom: 5px;
  left: 67px;
  position: absolute;
  opacity: 0.8;
  z-index: 3;
}
.reasons-arrow:hover {
  background: rgb(251, 169, 46);
  color: #fff;
  opacity: 1;
}
#publisher-slider-button-prev {
  padding-right: 6px;
  left: 20px;
}
.reason-image,
#reasons-list-1 {
  animation: 0.4s publisherSlideInLeft;
}
#reasons-list-2 {
  animation: 0.4s publisherSlideInRight;
}

.payment-text-container {
  max-width: 552px;
  margin: 0 auto;
}

.payment-text-container h2,
.payment-text-container h3 {
  text-align: left;
  margin: 20px 0;
  font-size: 2.375rem;
  font-weight: 600;
  line-height: 2.9375rem;
  color: #012d6c;
}
.payment-text-container p {
  text-align: left;
  font-size: 1.3125rem;
  font-weight: 300;
  line-height: 2rem;
  color: #9b9b9b;
}
.payment-text-container p b {
  font-weight: 600;
}
/* .payment-text-container p:first-of-type {
  font-size: 1.3125rem;
  line-height: 1.9375rem;
} */
.payment-text-container h3 {
  font-size: 1.75rem;
  line-height: 2.25rem;
}
/* PUBLISHER CAROUSEL SECTION */
.publisher-carousel-section {
  overflow-x: hidden;
}

.publisher-carousel-section h3 {
  color: #1179bf;
  font-size: 2rem;
  font-weight: 700;
  text-align: center;
}

.publisher-carousel-section p {
  margin-top: 1rem;
  color: #3f3f3f;
  font-size: 1.125rem;
  text-align: center;
}

.publisher-carousel-section .testimonial-square-right {
  position: absolute;
  right: 0;
  top: 0;
  width: 220px;
}

.publisher-carousel-section .testimonial-square-left {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 250px;
}

.publisher-carousel-section .shadow-effect > h3 {
  margin: 5rem 0 10px 0;
  text-align: center;
  display: block;
  font-size: 1.3125rem;
  font-weight: 700;
  color: #f9ae3b;
}

.publisher-carousel-section .carousel-position-name {
  display: block;
  font-size: 1rem;
  font-weight: 400;
  text-align: center;
  color: #9b9b9b;
  margin: 10px 0 20px;
}
.publisher-carousel-section .owl-carousel .owl-stage-outer {
  overflow: visible;
}

.publisher-carousel-section .arrow-right {
  position: absolute;
  right: 16%;
  cursor: pointer;
  z-index: 2;
  user-select: none;
  transition: all 0.4s;
}
.publisher-carousel-section .arrow-left {
  position: absolute;
  left: 16%;
  cursor: pointer;
  user-select: none;
  z-index: 2;
  transition: all 0.4s;
}
.publisher-carousel-section .arrow-left:hover,
.publisher-carousel-section .arrow-right:hover {
  transform: scale(1.4);
  opacity: 0.8;
}
#customers-testimonials .shadow-effect p {
  text-align: center;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.875rem;
  max-width: 600px;
  margin: 0 auto;
}
.testimonial-name {
  margin: -17px auto 0;
  display: table;
  width: auto;
  background: #3190e7;
  padding: 9px 35px;
  border-radius: 12px;
  text-align: center;
  color: #fff;
  box-shadow: 0 9px 18px rgba(0, 0, 0, 0.12), 0 5px 7px rgba(0, 0, 0, 0.05);
}

#customers-testimonials .item {
  box-shadow: 0px 15px 30px #0000000d;
  border-radius: 20px;
  position: relative;
  text-align: center;
  background: linear-gradient(
    109.23deg,
    rgba(255, 255, 255, 0.83) 44.8%,
    rgba(225, 225, 225, 0) 170.93%
  );
  padding: 40px;
  -webkit-transform: scale3d(0.8, 0.8, 1);
  transform: scale3d(0.8, 0.8, 1);
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

#customers-testimonials .owl-item.active.center .item {
  opacity: 1;
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
  background: linear-gradient(
    109.23deg,
    rgba(255, 255, 255, 0.83) 44.8%,
    rgba(225, 225, 225, 0) 170.93%
  );
  border-radius: 20px;
}
.publisher-carousel-section .owl-carousel .owl-item img {
  border-radius: 60px;
  position: absolute;
  top: -6.75rem;
  left: 50%;
  filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.15));
  transform: translateX(-50%);
  transform-style: preserve-3d;
  width: 12.5rem;
  height: 12.5rem;
  margin: 0 auto 17px;
}
#customers-testimonials.owl-carousel .owl-dots {
  margin-top: 1rem;
  text-align: center;
}
#customers-testimonials.owl-carousel .owl-dots .owl-dot {
  display: inline-block;
}
#customers-testimonials.owl-carousel .owl-dots .owl-dot span {
  height: 10px;
  width: 10px;
  background: #f0f0f0;
  display: inline-block;
  border-radius: 50%;
  margin: 0 5px;
  transform-origin: 50% 50% 0;
  transition: all 250ms ease-out 0s;
}

#customers-testimonials.owl-carousel .owl-dots .owl-dot.active span,
#customers-testimonials.owl-carousel .owl-dots .owl-dot:hover span {
  background: #f9ae3b;
}

/* PUBLISHER CAROUSEL SECTION */
.publisher-contact-section #stars,
.publisher-contact-section #stars2,
.publisher-contact-section #stars3 {
  display: none;
}
.publisher-contact-content {
  padding: 30px;
}
.publisher-contact-content h2 {
  text-align: left;
  font-size: 2.375rem;
  font-weight: 600;
  margin-bottom: 20px;
  line-height: 2.9375rem;
  color: #012d6c;
}
.publisher-contact-content p {
  text-align: left;
  font-size: 1.3125rem;
  font-weight: 300;
  /* max-width: 500px; */
  line-height: 1.9375rem;
  color: #9b9b9b;
}
.publisher-contact-content ul {
  list-style-type: square;
  margin-left: 40px;
  color: #9b9b9b;
  font-size: 1.3125rem;
  font-weight: 300;
  line-height: 1.9375rem;
}
.publisher-contact-content ul li {
  margin: 20px 0;
}
.publisher-contact-form form {
  overflow: auto;
  width: 100%;
  background: #99c6e4;
}
.publisher-contact-form input,
.publisher-contact-form textarea {
  height: 60px;
  outline: none;
  border-radius: 15px;
  border: none;
  padding-left: 22px;
  width: 300px;
  margin: 20px auto;
  max-width: 400px;
  display: block;
  transition: all 0.4s;
}
.publisher-contact-form input:focus,
.publisher-contact-form textarea:focus {
  width: 320px;
}
.publisher-contact-form textarea {
  height: auto;
  padding-top: 22px;
}
.publisher-contact-form .call-action {
  margin: 20px auto;
  display: block;
  background: transparent;
  border: 3px solid #012d6c;
  color: #012d6c;
  line-height: 0.3125rem;
  transition: all 0.4s;
}

.publisher-contact-form .call-action:hover {
  border: 3px solid #fff;
  color: #fff;
}
.satelite-overflow-path,
.satelite-full-path {
  display: none;
}
.satelite-image {
  display: none;
  animation: pulsingEffect 4s linear infinite;
}
@keyframes pulsingEffect {
  0% {
    transform: scale(0.8);
    box-shadow: 0px 0px 0px 0px rgba(255, 255, 255, 0.5),
      inset 0px 0px 0px 0px rgba(255, 255, 255, 0.5);
  }

  50% {
    transform: scale(1);
    box-shadow: 0px 0px 20px 9px rgba(255, 255, 255, 0),
      inset 0px 0px 20px 9px rgba(255, 255, 255, 0);
  }

  100% {
    transform: scale(0.8);
    box-shadow: 0px 0px 0px 0px rgba(255, 255, 255, 0.5),
      inset 0px 0px 0px 0px rgba(255, 255, 255, 0.5);
  }
}
@keyframes publisherSlideInLeft {
  from {
    right: -800px;
  }
  to {
    right: 0;
  }
}
@keyframes publisherSlideInRight {
  from {
    left: -800px;
  }
  to {
    left: 0;
  }
}
/* PUBLISHER PAGE */

/* PUSH NOTIFICATION ADS PAGE */
/* HERO SECTION */

.push-hero-section .container {
  margin-bottom: 70px;
}

.push-hero-section p {
  color: #6681a6;
  /* width: 60%; */
}

.push-hero-section .call-action {
  margin-top: 60px;
}

/* FEATURES SECTION */
.push-features-section {
  font-weight: 400;
  font-size: 1.3125rem;
  line-height: 1.5625rem;
}

.push-features-section .container {
  margin: 50px 0;
}

.push-features-section .card {
  height: 334px;
  padding-top: 0px;
  padding-left: 0px;
  padding-right: 0px;
}

.push-features-section .card .card-img {
  height: 150px;
}

.push-features-section h3 {
  font-size: 2.375rem;
  line-height: 2.9375rem;
}

/* BLEND PUSH ADS SECTION */
.push-blend-push-ads-section {
  background-color: #f8f8f8;
  padding: 130px 5px;
}

.push-blend-push-ads-section p {
  text-align: center;
  max-width: 1235px;
  margin: 0 auto;
  font-size: 3.1875rem;
  line-height: 3.8125rem;
  font-weight: 700;
  color: #012d6c;
}

/* PAYMENT OPTIONS SECTION */
.push-payment-options-section {
  text-align: center;
  padding: 100px 5px;
  font-weight: 400;
  font-size: 1.3125rem;
  line-height: 1.5625rem;
  color: #3f3f3f;
}

.push-payment-options-section .heading,
.push-payment-options-section .card .title {
  font-weight: 600;
  font-size: 2.375rem;
  line-height: 2.9375rem;
  color: #012d6c;
}

.push-payment-options-section .card .title {
  margin: 34px 0px;
}

.push-payment-options-section > div {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.push-payment-options-section .card {
  margin-top: 10px;
  max-width: 540px;
}

/* DISCOVER SECTION */
.push-discover-section {
  background-color: #f8f8f8;
  padding: 120px 5px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.push-discover-section .heading {
  font-weight: 600;
  font-size: 2.375rem;
  line-height: 2.9375rem;
  color: #012d6c;
  max-width: 1000px;
  text-align: center;
  margin-bottom: 140px;
}

.push-discover-section > div {
  display: flex;
  position: relative;
  align-items: center;
  max-width: 1000px;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
}
.advantages {
  padding-left: 15px;
}
.push-discover-section .advantages p {
  font-weight: 600;
  font-size: 1.75rem;
  max-width: 400px;
  line-height: 2.1875rem;
  color: #012d6c;
}

.push-discover-section .advantages ul {
  font-size: 1.3125rem;
  line-height: 1.9375rem;
  font-weight: 400;
  color: #3f3f3f;
  position: relative;
  margin-top: 20px;
  left: 16px;
}
.push-discover-section .advantages ul li {
  list-style: none;
  line-height: 50px;
}
.push-discover-section img {
  display: none;
}

.push-discover2-section {
  padding: 20px 5px 100px;
}

.two-column-section .row {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.two-column-section .row.image-right {
  flex-direction: column-reverse;
}

.two-column-section .row > div {
  max-width: 600px;
}

.two-column-section .row img {
  width: 50%;
}

.two-column-section .row .image {
  text-align: center;
}

.two-column-section .row h2 {
  font-size: 1.75rem;
  line-height: 2.1875rem;
  font-weight: 600;
  color: #012d6c;
  margin-bottom: 30px;
}

.two-column-section .row p {
  font-size: 1.3125rem;
  line-height: 1.9375rem;
  font-weight: 300;
  color: #9b9b9b;
}

.two-column-section .row .text {
  padding: 0 20px;
}

/* SUPPORTED BROWSERS SECTION */
.push-supported-browsers {
  padding: 50px 5px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.push-supported-browsers .heading {
  font-size: 1.75rem;
  line-height: 2.1875rem;
  font-weight: 600;
  color: #012d6c;
  text-align: center;
  max-width: 830px;
  margin-bottom: 30px;
}

.push-supported-browsers .browsers {
  width: 100%;
  display: flex;
  justify-content: space-around;
  text-align: center;
  flex-wrap: wrap;
  color: #9b9b9b;
}

.push-supported-browsers .browser-name {
  font-size: 1.3125rem;
  line-height: 1.9375rem;
  font-weight: 700;
  margin: 15px 0px;
}
.os-icons-container img {
  height: 45px;
  margin: 0 1px;
}

.push-supported-browsers .browser p {
  font-size: 1rem;
  line-height: 1.1875rem;
  font-weight: 300;
}

.push-supported-browsers .browser {
  margin: 30px 10px;
}

/* GET PAID SECTION */
.push-get-paid-section {
  padding-top: 100px;
}

/* CONTACT US PAGE */
.contact-form-section {
  display: flex;
}

.contact-form-section > div {
  width: 100%;
  padding: 100px 5px;
}

.contact-form-section .form-wrapper {
  max-width: 590px;
  margin: 0 auto;
  padding: 0 20px;
  text-align: center;
  position: relative;
}

.contact-form-section h1 {
  font-size: 1.75rem;
  line-height: 2.1875rem;
  font-weight: 600;
  color: #012d6c;
  margin-bottom: 20px;
}

.contact-form-section p {
  font-size: 13px;
  line-height: 21px;
  font-weight: 300;
  color: #9b9b9b;
  margin-bottom: 40px;
}

.contact-form-section input,
.contact-form-section select,
.contact-form-section textarea {
  display: block;
  width: 100%;
  outline: none;
  border: none;
  -webkit-appearance: none;
  background: #f8f8f8;
  border-radius: 20px;
  padding: 15px 0 15px 25px;
  height: 65px;
  margin-bottom: 13px;
  font-size: 1.3125rem;
  line-height: 1.9375rem;
  font-weight: 300;
}

.contact-form-section textarea {
  min-height: 200px;
  resize: none;
}

/* AD FORMATS PAGE */

.ad-formats-main-section h1 {
  font-size: 2.375rem;
  line-height: 2.9375rem;
  font-weight: 600;
  color: #012d6c;
  text-align: center;
  margin: 50px;
}

.ad-formats-main-section p {
  font-size: 1.3125rem;
  line-height: 1.5625rem;
  font-weight: 300;
  color: #9b9b9b;
  text-align: center;
  max-width: 830px;
  margin: 0 auto;
}
.ad-formats-main-section > div h2 {
  font-size: 2rem;
  font-weight: 600;
  color: #012d6c;
  margin: 50px 0 0;
}
.ad-formats-main-section .tab-button {
  display: block;
  background: white;
  box-shadow: 0px 15px 30px #00000005;
  border: 3px solid #99abc4;
  border-radius: 15px;
  font-weight: 400;
  font-size: 1.3125rem;
  line-height: 1.5625rem;
  color: #99abc4;
  padding: 28px 0;
  text-align: center;
  width: 340px;
  margin: 0 auto 10px;
}

.ad-formats-main-section .tab-button.active {
  background: #0071bd;
  border: 3px solid #0071bd;
  box-shadow: 0px 15px 30px #f9ae3b26;
  color: white;
  font-weight: 600;
}

.ad-formats-main-section .widget-button {
  background: white;
  box-shadow: 0px 15px 30px #cce2f166;
  border: 3px solid #98c5e3;
  font-weight: 600;
  font-size: 1.3125rem;
  line-height: 1.625rem;
  cursor: pointer;
  user-select: none;
  color: #012d6c;
  text-align: center;
  width: 321px;
  padding: 26px 0;
  position: relative;
  margin: 10px;
}

.ad-formats-main-section > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 90px;
  background: #f8f8f8;
}

.ad-formats-main-section .display-area {
  display: flex;
  justify-content: center;
  margin: 50px 0;
  flex-wrap: wrap;
}

.ad-formats-main-section .left-buttons {
  display: flex;
  max-width: 370px;
  width: 100%;
  flex-direction: column;
  justify-content: flex-end;
  padding-bottom: 30px;
}

.ad-formats-main-section .display-area .image {
  margin: 20px;
}

.ad-formats-main-section .display-area img {
  width: 100%;
}

.ad-formats-main-section .right-buttons {
  max-width: 370px;
  width: 100%;
}

.ad-formats-main-section .widget-button.active {
  color: white;
  background: #f9ae3b;
  border: 3px solid #f9ae3b;
}

.ad-formats-main-section button:focus {
  outline: none;
}

/* SMARTLINKS PAGE */
/* HERO SECTION */
.smartlink-hero-section p {
  color: #f8f8f8;
  /* width: 60%; */
  line-height: 1.5625rem;
  margin-bottom: 100px;
}

/* WHAT ARE SMARTLINKS SECTION */
.smartlink-what-are-section {
  padding: 0px 30px 30px;
}

.smartlink-what-are-section h2 {
  font-size: 2.375rem;
  line-height: 2.9375rem;
}

.smartlink-what-are-section p strong {
  font-weight: 700;
}

.smartlink-what-are-section.two-column-section .text {
  max-width: 700px;
}

/* INSTANT INCOME SECTION */
.smartlink-instant-income-section {
  padding: 0 5px;
}

.smartlink-instant-income-section h2 {
  font-size: 4.1875rem;
  line-height: 5.0625rem;
  font-weight: 700;
  color: #3f3f3f;
  text-align: center;
}

.smartlink-instant-income-section > p {
  font-size: 1.3125rem;
  line-height: 1.9375rem;
  font-weight: 300;
  color: #9b9b9b;
  text-align: center;
  max-width: 850px;
  margin: 10px auto 40px;
}

.smartlink-instant-income-section > div {
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.smartlink-instant-income-section .line {
  display: none;
}

.smartlink-instant-income-section .step {
  text-align: center;
  font-size: 1.3125rem;
  line-height: 1.625rem;
  font-weight: 600;
  color: #3f3f3f;
  margin-bottom: 30px;
}

.smartlink-instant-income-section .step img {
  display: block;
  width: 50%;
  margin: 0 auto;
}

.smartlink-instant-income-section .step p {
  margin-top: 30px;
}

.smartlink-instant-income2-section.two-column-section .row {
  margin-bottom: 100px;
}

.smartlink-instant-income2-section.two-column-section h2 {
  font-size: 2.375rem;
  line-height: 2.9375rem;
}

.smartlink-instant-income2-section.two-column-section .text {
  max-width: 700px;
}
/* ABOUT US PAGE */
.hero-section-about-us {
  background: #012d6c;
  background-image: url(../images/about_us_hero_bg.svg);
  overflow: hidden;
}
.flex-sb {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.bold {
  font-weight: 700;
}
.hero-section-about-us h1 {
  text-align: center;
  margin-bottom: 30px;
  font-size: 3.1875rem;
  line-height: 3.8125rem;
  font-weight: 700;
  color: #ffffff;
  opacity: 1;
}
.hero-section-about-us .reasons-list-container {
  vertical-align: top;
  /* margin-top: 5%; */
}
.hero-section-about-us p {
  text-align: center;
  font-size: 1.3125rem;
  margin-bottom: 30px;
  line-height: 1.5625rem;
  font-weight: 300;
  color: #0071bd;
}
.hero-section-about-us p:first-of-type {
  color: #fff;
}
.hero-section-about-us .reason-image {
  background-image: url(../images/about_us_hero_new.png);
}
.about-ticker-section {
  background: #0071bd;
  text-align: center;
  overflow: auto;
}
.typewriter-container {
  display: inline-block;
}
.about-ticker-section p {
  font-size: 2.5rem;
  font-weight: 700;
  display: inline-block;
  line-height: 3.125rem;
  overflow: hidden;
  margin: 60px auto;
  color: #ffffff;
}
.about-us-time-section .row .text p {
  margin-bottom: 30px;
}
.about-mission-section {
  background: #0071bd;
}
.about-mission-section h2 {
  margin: 0 0 30px 0;
}
.about-mission-section .reason-image {
  background-image: url(../images/about_us_paintig.svg);
}
.about-mission-section p {
  line-height: 1.9375rem;
  color: #ffffff;
}
.about-mission-section p.about-mission-yellow {
  font-size: 1.75rem;
  line-height: 2.1875rem;
  font-weight: 600;
  text-align: center;
  color: #f9ae3b;
}
.about-us-goals-section .card h3 {
  margin-top: 15%;
}
.about-us-goals-section .card {
  height: 210px;
}
.about-us-goals-section .card .card-img {
  height: 30px;
  position: absolute;
  top: -13px;
}
.about-us-leaders h2 {
  text-align: center;
  font-size: 4.1875rem;
  font-weight: 700;
  line-height: 5.0625rem;
  color: #3f3f3f;
}
.about-us-leaders p {
  max-width: 690px;
}
.about-us-leaders div.container {
  margin-top: 200px;
}
.about-us-leaders .container .card {
  background: #f8f8f8;
  padding: 40px;
  border-radius: 25px;
  position: relative;
  width: 359px;
  height: 353px;
  margin-bottom: 150px;
}
.about-us-leaders .container .card img {
  position: absolute;
  top: -70%;
  width: 260px;
  left: 50%;
  transform: translate(-50%, 50%);
}
.about-us-leaders .card-content {
  position: absolute;
  width: 100%;
  top: 65%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.about-us-leaders .card-content h3 {
  margin-bottom: 20px;
}
.about-us-leaders .card-content p {
  margin: 0;
}
.about-us-leaders .leaders-links-container {
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
  bottom: 0;
}
.about-us-leaders .card .leaders-links-container a.leaders-link {
  border: none;
  width: 80px;
  position: relative;
  display: inline-block;
}
.about-us-leaders .card .leaders-links-container img {
  width: 55px;
}
.about-carousel-header h3 {
  color: #3f3f3f;
  letter-spacing: 0;
  color: #9b9b9b;
  font-weight: 600;
  font-size: 1.75rem;
  line-height: 2.1875rem;
}
.about-carousel-header p {
  font-weight: 300;
  font-size: 1.3125rem;
  text-align: center;
  color: #9b9b9b;
  line-height: 1.9375rem;
  max-width: 480px;
  margin: 0 auto;
}
.div-wrapp-about-us {
  padding: 20px 0;
  display: flex;
  flex-direction: column;
}
.div-wrapp-about-us > div {
  display: flex;
  padding: 16px 0;
  border-bottom: 2px solid #f5f5f5;
  flex-direction: row;
  align-items: center;
}
.contact-info-details p {
  color: #012d6c;
  font-weight: 600;
  margin-bottom: 8px;
}
.contact-info-details span {
  color: #9b9b9b;
  line-height: 1.8;
}
.about-us-contact-section h2 {
  font-size: 3.1875rem;
  font-weight: 700;
  line-height: 3.5625rem;
  color: #012d6c;
}
.about-us-contact-section p span {
  color: #022d6c;
  display: block;
  margin-top: 10px;
  font-weight: 400;
}
.about-us-gallery {
  padding: 40px 10px;
}
.gallery-text-section {
  text-align: center;
}

.gallery-slider {
  background-image: url(../images/about-us/about_us_gallery.png);
  height: 500px;
  animation: backgroundScroll 60s linear infinite;
}

.gallery-slider:hover {
  -webkit-animation-play-state: paused;
  -moz-animation-play-state: paused;
  -o-animation-play-state: paused;
  animation-play-state: paused;
  cursor: pointer;
}

.container-confetti {
  margin-bottom: 80px;
  width: 100%;
  background-color: #f8f8f8;
  height: 400px;
  position: relative;
}

/* CPA OFFERS PAGE */
.hero-section-cpa {
  overflow: visible;
  padding-bottom: 20px;
  text-align: center;
}
.hero-section-cpa .reason-image {
  background-image: none;
  position: relative;
  bottom: -40px;
  text-align: center;
}
.hero-section-cpa .reason-image img {
  transform: rotate(180deg);
  height: 100%;
}
.hero-section-cpa h1 {
  font-size: 3.1875rem;
  line-height: 3.8125rem;
}
.cpa-offers-section div.row {
  margin-bottom: 80px;
}
.extension-fast-section div.row {
  margin-bottom: 30px;
}
.cpa-localized-section {
  background: #f8f8f8;
}
.cpa-localized-section .row h2 {
  font-size: 3.1875rem;
  font-weight: 700;
  line-height: 3.8125rem;
  color: #012d6c;
}
.cpa-localized-section .row p {
  font-size: 1.3125rem;
  font-weight: 300;
  line-height: 1.9375rem;
  color: #335789;
}
.cpi-platform-section {
  background: #0071bd;
  text-align: center;
  overflow: auto;
}
.cpi-platform-section h2 {
  font-size: 2.375rem;
  line-height: 2.9375rem;
  font-weight: 600;
  color: #fff;
  margin: 40px auto;
}
.cpi-platform-section p {
  font-size: 1.3125rem;
  line-height: 1.9375rem;
  max-width: 920px;
  padding: 0 10px;
  font-weight: 300;
  margin: 40px auto;
  color: #ccd5e1;
}
.cpi-platform-section img {
  width: 80%;
  object-fit: contain;
}
.cpa-assistance-section {
  overflow: auto;
}
.cpa-assistance-section h2 {
  font-size: 2.375rem;
  line-height: 2.9375rem;
  max-width: 700px;
  font-weight: 600;
  margin: 0 auto 60px;
  color: #012d6c;
  text-align: center;
}
.cpa-assistance-section .row h3 {
  text-align: left;
  font-size: 1.9rem;
  line-height: 2.2rem;
  margin-bottom: 15px;
  font-weight: 600;
  color: #012d6c;
}
/* CPA OFFERS PAGE */
/* The typing effect */
@keyframes typewriter {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}
@keyframes blinkTextCursor {
  from {
    border-right-color: rgba(255, 255, 255, 0.75);
  }
  to {
    border-right-color: transparent;
  }
}
@keyframes spiniiii {
  0% {
    content: 'i';
  }
  20% {
    content: 'ipsum';
  }
  40% {
    content: 't';
  }
  60% {
    content: 'test';
  }
  80% {
    content: 'd';
  }
  100% {
    content: 'drugi test';
  }
}

/* NATIVE ADS PAGE */
/* HERO SECTION */
.native-hero-section p {
  color: #6681a6;
  /* width: 70%; */
  /* margin-bottom: 100px; */
}

.native-hero-section img {
  margin-left: 30px;
  width: 90%;
}

/* DETAILS SECTION */
.native-details-section {
  padding: 10px 30px 0;
}

.native-details-section.two-column-section h2 {
  font-size: 2.375rem;
  line-height: 2.9375rem;
}

.native-details-section.two-column-section .row img {
  margin-bottom: 30px;
  max-height: 320px;
}

/* BOOST SECTION */
.native-boost-section {
  background: #cce2f1;
  padding: 0 5px 5px;
}

.native-boost-section > div {
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 2.375rem;
  line-height: 2.9375rem;
  color: #012d6c;
  max-width: 1360px;
  margin: 0 auto;
}

.native-boost-section img {
  margin-top: -25px;
  margin-right: 80px;
  display: none;
}

.native-boost-section span {
  color: #ff6a40;
}

.native-boost-section div div {
  max-width: 900px;
  margin: 0 auto;
  padding: 70px 0;
}

/* ADVANTAGES SECTION */
.native-advantages-section {
  padding: 80px 20px;
}

.native-advantages-section > p {
  text-align: center;
  font-size: 2.9375rem;
  line-height: 3.375rem;
  font-weight: 700;
  color: #012d6c;
  margin-bottom: 125px;
}
.native-advantages-section > p span {
  display: block;
  font-size: 1.875rem;
  font-weight: 400;
  line-height: 2.1875rem;
}
.native-advantages-section > div {
  max-width: 1500px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}

.native-advantages-section .big-image {
  display: none;
}

.native-advantages-section .items {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 auto;
}

.native-advantages-section .item h2 {
  font-size: 2.375rem;
  line-height: 2.9375rem;
  font-weight: 600;
  color: #012d6c;
  margin-bottom: 50px;
}

.native-advantages-section .item div {
  display: flex;
  margin-bottom: 50px;
}

.native-advantages-section .item div img {
  margin-right: 78px;
}

.native-advantages-section .item p {
  font-size: 1.3125rem;
  line-height: 1.9375rem;
  font-weight: 300;
  color: #9b9b9b;
  max-width: 400px;
}

/* AD FORMATS SECTION */
.native-ad-formats-section {
  padding: 20px 30px;
  font-weight: 300;
  font-size: 1.3125rem;
  line-height: 1.9375rem;
  color: #9b9b9b;
}

.native-ad-formats-section .heading {
  font-weight: 700;
  font-size: 3.1875rem;
  line-height: 3.8125rem;
  color: #3f3f3f;
  text-align: center;
}

.native-ad-formats-section .subheading {
  text-align: center;
  margin: 25px 0 110px;
}

.native-ad-formats-section > div {
  max-width: 1330px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.native-ad-formats-section .item {
  max-width: 398px;
}

.native-ad-formats-section .item h2 {
  font-size: 1.75rem;
  line-height: 2.1875rem;
  font-weight: 600;
  color: #012d6c;
  margin-bottom: 30px;
}

.native-ad-formats-section .item img {
  display: block;
  width: 80%;
}

.native-ad-formats-section .item p {
  margin: 30px 0 50px;
}

/* MEDIA OUTLETS SECTION */
.native-media-outlets-section {
  background: #cce2f1;
  padding: 100px 5px;
  text-align: center;
  font-size: 1.3125rem;
  line-height: 1.9375rem;
  font-weight: 300;
  color: #6681a6;
}

.native-media-outlets-section h2 {
  font-size: 2.9375rem;
  line-height: 3.375rem;
  font-weight: 700;
  color: #012d6c;
  margin-bottom: 50px;
}

.native-media-outlets-section p {
  max-width: 850px;
  margin: 0 auto;
}

.native-features-section {
  font-weight: 400;
  font-size: 1.3125rem;
  line-height: 1.5625rem;
  background: #cce2f1;
}

.native-features-section .container {
  margin-bottom: 50px;
}

.native-features-section .card {
  padding-top: 0px;
  padding-left: 0px;
  padding-right: 0px;
}

.native-features-section .card .card-img {
  height: 160px;
}

.native-features-section h3 {
  font-size: 2.375rem;
  line-height: 2.9375rem;
  max-width: 300px;
}

/* ADVANTAGES 2 SECTION */
.native-advantages2-section {
  padding: 20px;
  font-size: 1.3125rem;
  line-height: 1.9375rem;
  font-weight: 300;
  color: #9b9b9b;
}

.native-advantages2-section > div {
  max-width: 1380px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin: 0 auto;
}

.native-advantages2-section .item {
  margin-bottom: 50px;
}

.native-advantages2-section .item h2 {
  font-size: 1.75rem;
  line-height: 2.1875rem;
  font-weight: 600;
  color: #012d6c;
  margin-bottom: 50px;
}

.native-advantages2-section .item div {
  display: flex;
}

.native-advantages2-section .item img {
  margin-right: 60px;
}

.native-advantages2-section .item p {
  max-width: 400px;
}
/* SIGN UP PAGE */

.sign-up-container {
  background: url(../images/sign_in_background.svg);
  background-size: cover;
  background-position: bottom center;
  background-repeat: no-repeat;

  position: relative;

  text-align: center;
  overflow: auto;
  overflow-x: hidden;
}
.sign-up-box {
  background: #fff;
  max-width: 600px;
  margin: 50px auto 100px;
  border-radius: 50px;
  padding: 20px 50px 40px;
}
.sign-up-box h1 {
  font-size: 1.75rem;
  line-height: 2.1875rem;
  font-weight: 600;
  color: #012d6c;
  margin: 20px 0;
}
.sign-up-box .sign-up-header {
  max-width: 200px;
  color: #ccd5e1;
  font-size: 0.8125rem;
  line-height: 1.3125rem;
  font-weight: 300;
  margin: 10px auto;
}
#logo-img-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
#logo-img-container img {
  height: 30px;
  cursor: pointer;
}
/* .sign-up-box img {
  height: 30px;
  margin-top: 20px;
} */
.sign-acc-type {
  text-align: left;
  font-size: 1rem;
  line-height: 1.1875rem;
  font-weight: 600;
  color: #9b9b9b;
}
.checkmark-container {
  display: inline-block;
  position: relative;
  padding-left: 35px;
  margin: 10px 20px 20px 0;
  cursor: pointer;
  font-size: 1rem;
  line-height: 1.5625rem;
  font-weight: 400;
  color: #9b9b9b;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.checkmark-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  border-radius: 50%;
  background-color: #eee;
}

/* On mouse-over, add a grey background color */
.checkmark-container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.checkmark-container input:checked ~ .checkmark {
  background-color: #2196f3;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkmark-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkmark-container .checkmark:after {
  left: 9px;
  top: 5px;
  width: 7px;
  height: 13px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.chekcboxes-type-container {
  text-align: left;
  margin: 10px 0;
}
.required-message {
  display: none;
  text-align: left;
  color: #ff7878;
  font-size: 12px;
}
.sign-up-form input,
.sign-up-form select {
  display: block;
  background: #f8f8f8;
  border-radius: 12px;
  outline: none;
  border: none;
  padding: 15px;
  margin: 15px 0;
  letter-spacing: 1px;
}
.sign-up-form input.required:invalid,
.sign-up-form select.required:invalid,
.sign-up-form textarea.required:invalid {
  border: 1px solid #ff7878;
}
.sign-up-form input.required:invalid + .required-message,
.sign-up-form select.required:invalid + .required-message {
  display: block;
}
input.required:invalid ~ .checkmark {
  border: 1px solid red;
}
.sign-up-form select {
  -webkit-appearance: none;
  height: 48px;
}
.sign-up-form .half-input {
  display: block;
  width: 100%;
}
#individual-form {
  display: none;
}
.sign-up-form .full-input {
  width: 100%;
}
.form-checkboxes-container {
  text-align: left;
}
.form-checkboxes-container .checkmark-container {
  display: block;
}
.form-checkboxes-container a {
  color: #0071bd;
  font-weight: 600;
}
.sign-up-form button {
  background: #f9ae3b;
  border-radius: 15px;
  padding: 15px 60px;
  letter-spacing: 1.6px;
  text-align: center;
  font-weight: 600;
  color: #ffffff;
  line-height: 1.1875rem;
  outline: none;
}
.form-checkboxes-container p {
  font-size: 0.8125rem;
  line-height: 1rem;
  letter-spacing: 0;
  margin: 10px 0 20px;
  color: #ccd5e1;
}
.sign-up-login-section {
  text-align: left;
  padding: 15px 0;
  font-size: 1rem;
  border-top: 2px solid #eee;
  border-bottom: 2px solid #eee;
  margin: 25px 0 10px;
}
.sign-up-login-section h2,
.sign-up-footer p {
  color: #9b9b9b;
  margin-bottom: 10px;
}
.sign-up-login-section a,
.sign-up-footer a {
  color: #0071bd;
  text-decoration: underline;
  margin: 10px 0;
}
.sign-up-footer {
  padding-bottom: 20px;
  text-align: left;
}
.bushes-container {
  width: 4000px;
  height: 150px;
  background-repeat: repeat-x;
  background-position: top;
  background: url(../images/sign_in_bush.svg);
  position: absolute;
  left: -221px;
  bottom: 0;
}
.login-go-home {
  margin: 0 auto;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  color: #fff;
  bottom: 0;
  font-weight: 100;
  padding: 50px;
  z-index: 9999;
}
.login-go-home a {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  color: #cce2f1;
  text-decoration: underline;
  font-size: 0.9em;
}
.login-go-home img {
  width: 30px;
  margin-bottom: 10px;
}
/* SIGN UP PAGE */
/* EXTENSIONS PAGE */

.extensions-best-for-section .browser p {
  max-width: 220px;
  font-size: 1.3125rem;
  line-height: 1.9375rem;
  font-weight: 300;
  color: #9b9b9b;
}
.extensions-best-for-section p.browser-name {
  font-size: 1.75rem;
  line-height: 2.1875rem;
  font-weight: 600;
  height: 60px;
  color: #012d6c;
}
/* EXTENSIONS PAGE */

button:disabled {
  background-color: #a4a4a4;
}
/* CAREERS PAGE */
.careers-hero-section .call-action {
  padding: 25px 35px;
}
/*
.careers-perks {
  padding: 20px;
  background: #f8f8f8;
  position: relative;
  margin-bottom: 50px;
}

.careers-perks .container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 1200px;
  overflow: visible;
  margin: 0 auto;
}

.careers-perks .container div {
  border: none;
  margin: 20px;
}

.careers-perks .container div {
  position: relative;
}
.careers-perks .container div:last-of-type {
  border-bottom: none;
}
.careers-perks .container div img {
  height: 45px;
  position: absolute;
  top: -20px;
  left: 50%;
  transform: translateX(-50%);
}
.careers-perks .container ul.blue-perk {
  background: #0071bd;
  color: #fff;
}
.careers-perks .container ul.yellow-perk {
  background: #f9ae3b;
  color: #fff;
}
.careers-perks .container ul.red-perk {
  background: #ff6a40;
  color: #fff;
}
.careers-perks img {
  position: absolute;
  display: none;
  bottom: -37px;
  left: 50%;
  transform: translateX(-50%);
}
.careers-perks h2 {
  width: 100%;
  color: #012d6c;
  font-weight: 600;
  text-align: center;
  font-size: 28px;
  margin: 20px 0;
}
.careers-perks .container ul {
  margin: 0;
  list-style-type: disc;
  background: #fff;
  border-radius: 30px;
  list-style-position: outside;
  color: #99abc4;
  padding: 35px 50px;
  max-width: 350px;
}
.careers-perks .container ul:last-of-type {
  border: none;
}
.careers-perks .container li {
  font-size: 16px;
  line-height: 31px;
  font-weight: 300;
}
.careers-perks ul {
  list-style-type: square;
} */
.career-recruit {
  overflow-y: auto;
  text-align: center;
}

.career-ceo-section .advantages .ceo-quote {
  font-size: 18px;
  font-weight: 300;
  font-style: italic;
  margin: 20px 0;
}
.career-ceo-section .advantages .ceo-quote span:first-of-type {
  display: block;
  margin: 10px 0;
  font-size: 20px;
  font-weight: 600;
  font-style: normal;
}
.career-ceo-section img {
  max-width: 450px;
  top: -70px;
  left: -20px;
}
.offices-section {
  text-align: center;
}

.offices-section .section-subtitle {
  max-width: 750px;
  text-align: center;
  line-height: 2.375rem;
  margin: auto;
}
.offices-section .new-events div {
  background-size: contain;
  background-repeat: no-repeat;
  margin: 5px;
  border-radius: 15px;
  background-position: center;
}
.offices-section .new-events {
  justify-content: center;
}
.offices-section .event-1 {
  background-image: url(../images/office-1.png);
}
.offices-section .event-2 {
  background-image: url(../images/office-2.png);
}
.offices-section .event-3 {
  background-image: url(../images/office-3.png);
}
.offices-section .event-4 {
  background-image: url(../images/office-4.png);
}
.offices-section .event-5 {
  background-image: url(../images/office-5.png);
}
.careers-join-section {
  width: 100%;
  overflow: hidden;
  padding: 0 50px 20px;
  position: relative;
  text-align: center;
}
.careers-join-section h2 {
  position: relative;
  color: #fff;
  margin: 50px 0;
  z-index: 1;
  text-align: center;
  font-size: 2.375rem;
  line-height: 2.9375rem;
  font-weight: 600;
  color: #1179bf;
}

/*
.position-card:last-of-type {
  margin-bottom: 320px;
} */

/* CAREERS PAGE */

/* ACADEMY PAGE */
.academy-teach {
  background: #fff;
}
.academy-teach .heading {
  font-size: 2rem;
}
.academy-teach .browsers {
  max-width: 1200px;
}
.academy-teach .browser p {
  max-width: 320px;
  font-size: 1.2rem;
  font-weight: 400;
}
.academy-teach .browser p.browser-name {
  font-size: 1.5rem;
  font-weight: 600;
  max-width: 500px;
  margin-top: 40px;
}
.academy-skills-section {
  text-align: center;
  padding: 0 20px;
}
.academy-skills-section h3 {
  text-align: center;
  color: #012d6c;
  font-weight: 600;
  font-size: 2.3rem;
  margin: 40px auto 30px;
  max-width: 700px;
}
.academy-skills-section p {
  font-size: 1.3125rem;
  line-height: 1.9375rem;
  font-weight: 300;
  color: #9b9b9b;
  max-width: 500px;
  margin: 0 auto 60px;
}

.academy-benefits h2 {
  text-align: center;
  color: #012d6c;
  font-weight: 600;
  font-size: 2.3rem;
  margin: 40px 0;
}
.academy-benefits .item div {
  justify-content: center;
  align-items: center;
}
.academy-benefits .item div p {
  max-width: 300px;
}
.academy-benefits .item div p span {
  display: block;
  font-size: 1.75rem;
  font-weight: 600;
  color: #012d6c;
}
.academy-benefits .item img {
  margin-right: 10px;
}
.academy-about-coordinator {
  background: #fff;
}
.academy-about-coordinator p span {
  display: block;
  font-weight: 400;
  color: #0071bd;
}
.academy-about-coordinator .advantages .ceo-quote {
  max-width: 600px;
  font-style: normal;
}
.academy-about-coordinator img {
  display: block;
}
.courses-section {
  overflow-y: auto;
  background: #f8f8f8;
  text-align: center;
}
.courses-section h5 {
  text-align: center;
  color: #012d6c;
  font-weight: 600;
  font-size: 2.5rem;
  margin: 8rem 0;
}
.courses-section .cards {
  margin: 40px auto;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.courses-section .cards .card {
  width: 350px;
  border-radius: 30px;
  max-width: 300px;
  margin: 10px;
  overflow: hidden;
}
.courses-section .cards .header {
  overflow: auto;
  background: #fff;
  text-align: left;
}
.courses-section .cards .header > p {
  margin: 0 30px;
  padding: 20px 0px;
  border-bottom: 1px solid #f9ae3b;
  color: #f9ae3b;
  font-weight: 600;
  line-height: 1.3rem;
  font-size: 1rem;
}
.courses-section .cards .header > p span {
  display: block;
  font-weight: 300;
  font-size: 1rem;
}
.courses-section .cards .content {
  background: #fff;
  padding: 30px;
}
.course-name {
  font-size: 1.3rem;
  text-align: left;
  font-weight: 700;
  line-height: 1.5rem;
  color: #012d6c;
  height: 60px;
}
.course-name + p {
  text-align: left;
  height: 100px;
  text-align: left;
  font-weight: 400 Light;
  line-height: 1.3rem;
  font-size: 1rem;
  color: #9b9b9b;
}
.courses-section .card-footer {
  display: flex;
}

.card-footer div {
  width: 50%;
  display: flex;
  background: #0071bd;
  justify-content: center;
  align-items: center;
  height: 70px;
  color: #fff;
}
.card-footer div:first-of-type {
  background: #012d6c;
  flex: 1;
}
.card-footer div p {
  width: 100%;
  font-weight: 600;
  text-align: left;
  line-height: 1.3rem;
  font-size: 1rem;
  padding: 30px;
}
.card-footer p span {
  display: block;
  font-weight: 300;
  font-size: 1rem;
}
/* ACADEMY PAGE */

/* NEW EVENTS GRID  */
.new-events {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 4rem;
  max-width: 1200px;
}

.new-events a {
  padding: 20px;
  position: relative;
  margin: 10px auto;
  background-size: cover;
  background-position: center;
  transition: all 0.5s;
}

.new-events .centered-text {
  position: absolute;
  top: 50%;
  text-align: center;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: 600;
  color: #fff;
  font-size: 18px;
  z-index: 3;
}
.new-events .bottom-text {
  position: absolute;
  bottom: 1.25rem;
  left: 1.25rem;
  font-weight: 600;
  color: var(--color-white);
  font-size: 0.8rem;
  z-index: 3;
}

.bottom-text h3 {
  width: 80%;
  font-size: 1.25rem;
  font-weight: 700;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.new-events__item--3 .bottom-text h3 {
  width: 60%;
}

.bottom-text p {
  margin-top: 0.75rem;
  color: var(--color-white);
  font-size: 0.85rem;
  font-weight: 700;
}

.bottom-text span {
  display: block;
  margin-top: 0.25rem;
  font-size: 0.85rem;
  font-weight: 400;
}

/* news events animation */

.partialBorder {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  transition: 0.5s;
  -webkit-transform: scale(0.95);
  -moz-transform: scale(0.95);
  -o-transform: scale(0.95);
  transform: scale(0.95);
}

.partialBorder > div {
  position: absolute;
  height: 10%;
  width: 10%;
  border: 0.15vw solid #fff;
}

.partialBorder .leftTop {
  left: 0;
  top: 0;
  border-right: none;
  border-bottom: none;
}

.partialBorder .rightTop {
  right: 0;
  top: 0;
  border-left: none;
  border-bottom: none;
}

.partialBorder .leftBot {
  left: 0;
  bottom: 0;
  border-right: none;
  border-top: none;
}

.partialBorder .rightBot {
  right: 0;
  bottom: 0;
  border-left: none;
  border-top: none;
}

.new-events a:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0.6;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  will-change: transform, opacity;
  background-color: #012d6c;
  background-blend-mode: darken;
}

*:before,
*:after {
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  font-size: 0px;
}

/* NEW EVENTS GRID  */
.nav-links .dropdown,
.nav-links .dropdown-search {
  display: none;
}
.arrow {
  width: 12px;
  height: 12px;
  /* margin: 8px 0 0 6px; */
  background: url(../images/arrow-down.svg);
  background-size: cover;
  transition: all 0.1s linear;
}

/*Glossary page*/

.glossary-section .container {
  max-width: calc(1920px - 2 * (140px - 5px));
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
}

.glossary-header a:hover {
  display: block;
  border-bottom: 5px solid #f9ae3b;
}
.glossary-section .card {
  /*height: 200px;*/
  width: 100%;
  background: #fff;
  box-shadow: 0 15px 30px #338dca0d;
  border-radius: 20px;
  flex-direction: column;
  margin-bottom: 20px;
  justify-content: space-around;
  align-items: center;
  position: relative;
  text-align: left;
  padding: 40px;
  height: 90%;
}
.glossary-section h1 {
  font-weight: 600;
  font-size: 2rem;
  color: #012d6c;
  width: 100%;
  margin-bottom: 20px;
}
.glossary-section h3 {
  font-weight: 600;
  font-size: 1.3125rem;
  line-height: 1.625rem;
  color: #012d6c;
  width: 100%;
}
.glossary-section h4 {
  font-weight: 600;
  font-size: 1.3125rem;
  line-height: 1.625rem;
  color: #0071bd;
  width: 100%;
}
.glossary-section p {
  font-family: Montserrat;
  line-height: 1.3rem;
  font-style: normal;
  font-weight: lighter;
  font-size: 1rem;
  color: #9b9b9b;
  margin-top: 20px;
}
.glossary-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0 auto;
}
.glossary-container:not(:first-child) {
  border-top: 1px solid #9b9b9b;
}
.glossary-container a {
  display: block;
  width: 30%;
}
.glossary-ad {
  height: 300px;
  justify-content: center;
  align-self: center;
  display: flex;
}
.glossary-ad .left-col {
  width: 58.33333333%;
  align-self: center;
}
.glossary-ad .left-col div {
  width: 300px;
  margin: auto;
}
.glossary-ad .right-col {
  width: 41.66666666%;
}
.glossary-ad .login-pop-up {
  width: 80%;
  height: 260px;
  background: #99c6e4 0% 0% no-repeat padding-box;
  border-radius: 50px;
  margin-left: -10%;
}
.inner-box {
  background-color: #cce2f1;
  max-width: 900px;
  margin: 100px auto;
  border-radius: 20px;
  display: grid;
  grid-template-columns: 2fr 2fr;
}

.inner-box .information-wrapper {
  padding: 50px 0px 50px 10%;
}

.map-container iframe {
  width: 100%;
  height: 100%;
  border: none;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

.information-wrapper strong {
  font-size: 1.75rem;
  display: inline-block;
  color: #012d6c;
  margin: 1rem 0;
}

.information-wrapper p {
  color: #6b7786;
  margin-bottom: 0.5rem;
  font-size: 1.125rem;
}

.coming-soon-dot {
  opacity: 0;
}

.coming-soon-dot {
  animation: load 2.25s ease-in infinite;
}
.coming-soon-dot:nth-of-type(1) {
  animation-delay: 0.75s;
}
.coming-soon-dot:nth-of-type(2) {
  animation-delay: 1.5s;
}
.coming-soon-dot:nth-of-type(3) {
  animation-delay: 2.25s;
}

.footer__partners li:nth-child(3) img {
  max-height: 35px;
}

@media (max-width: 959px) {
  .footer__bottom {
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .footer__partners {
    flex-wrap: wrap;
    width: 100%;
    gap: 1rem;
  }

  .footer__partners li:nth-child(1) {
    order: 1;
  }
  .footer__partners li:nth-child(2) {
    order: 3;
  }

  .footer__partners li:nth-child(3) {
    order: 2;
  }
  .footer__partners li:nth-child(4) {
    order: 4;
  }
  .footer__partners li:nth-child(5) {
    order: 5;
  }

  .footer__partners li {
    margin: 0 0 32px 0;
  }
}
@keyframes load {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
